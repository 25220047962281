import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { WebAnalyticsService } from './web-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousUrl: string | undefined;
  private currentUrl: string | undefined;
  private targetUrl: string | undefined;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private styleTag: HTMLLinkElement;

  loading$ = this.loadingSubject.asObservable();

  constructor(private router: Router, private webAnalytics: WebAnalyticsService) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {  
        this.targetUrl = event.url;
        this.loadingStart();

        if (event.navigationTrigger === 'popstate') {
          location.reload();
        }

      }

      if (event instanceof NavigationEnd) {
        this.loadingEnd();
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (!this.currentUrl.includes('#')) {
          window.scrollTo(0, 0);

          webAnalytics.triggerPostNaviProcess();
        }
      }

      // Set loading state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel) {
        this.loadingEnd();
      }

      if (event instanceof NavigationError) {
        this.loadingEnd();
      }
    });
  }

  public getPreviousUrl(): string | undefined {
    return this.previousUrl;
  }

  public getCurrentUrl(): string | undefined {
    return this.currentUrl;
  }

  public getTargetUrl(): string | undefined {
    return this.targetUrl;
  }

  public loadingStart() {
    this.loadingSubject.next(true);
  }

  public loadingEnd() {
    this.loadingSubject.next(false);
  }

  private addStyle(href: string): void {
    //this.styleTag = this.renderer.createElement('link');
    this.styleTag.rel = 'stylesheet';
    this.styleTag.href = href;
    //this.renderer.appendChild(document.head, this.styleTag);
  }

  private removeStyle(): void {
    if (this.styleTag) {
      //this.renderer.removeChild(document.head, this.styleTag);
    }
  }

  private getRouteStyle(): string {
    // Define which stylesheet to use based on the current route
    // Example: return '/assets/styles/route-specific.css';
    return '/assets/styles/default.css'; // Default style as an example
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService, LocalStorageService, PayloadService } from '../../services';
import { IUser } from '@sw-cms/shared-types';
import { BaseComponentComponent } from '../base-component/base-component.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'web-fav-button',
  templateUrl: './fav-button.component.html',
  styleUrls: ['./fav-button.component.scss'],
})
export class FavButtonComponent extends BaseComponentComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() type: string = 'provider';

  constructor(
    private localStorageService: LocalStorageService,
    private payloadService: PayloadService,
    private authService: AuthService) {
    super();
  }

  isFavorited = false;
  key: string = '';
  user: IUser | null | undefined;

  override ngOnInit() {
   
    this.authService.user
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['id'] && changes['id'].currentValue) {
      this.key = `favorite_${this.type}_${this.id}`;
      const storedValue = localStorage.getItem(this.key);
      this.isFavorited = storedValue ? JSON.parse(storedValue) : false;  
    }
  }

  toggleFavorite() {
    this.isFavorited = !this.isFavorited;

    if (this.isFavorited) localStorage.setItem(this.key, JSON.stringify(this.isFavorited));
    if (this.type === 'provider') {
      this.payloadService.careTeamUpdate(this.user?.id ?? '', this.id, this.isFavorited ? 'add' : 'remove').then(x => {
        if (!this.isFavorited) this.removeFromStorage();
      });
    }
    else {
      this.removeFromStorage();
    }
  }

  removeFromStorage() {
    if (!this.isFavorited) localStorage.removeItem(this.key);
  }
}

import { Route } from '@angular/router';
import { Error404Component } from './shared/components';

export const appRoutes: Route[] = [
    {
        path: '',
        loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'provider',
        loadChildren: () => import('./provider/provider.module').then((m) => m.ProviderModule),
      },
      {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then((m) => m.LegalModule),
      },
      {
        path: 'my',
        loadChildren: () => import('./private/private.module').then((m) => m.PrivateModule),
      },
      {path: '**', redirectTo: '/error-404'}

];

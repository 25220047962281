import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'web-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnChanges {

  @Input() content: string;
  @Input() limit: number;
  @Input() completeWords: boolean;

  formattedContent: string;
  isContentToggled: boolean;
  truncated: boolean;
  trailingChars: string = '...';

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']?.currentValue) {
      this.formattedContent = this.formatContent(this.content);
    }
  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.formattedContent = this.isContentToggled ? this.content : this.formatContent(this.content);
  }

  formatContent(content: string) {
    // Ensure content is a string
    content = content ?? '';

    // Determine if the content exceeds the limit
    this.truncated = content.length > this.limit;

    // Adjust limit to complete words if the flag is set
    const adjustedLimit = this.completeWords 
        ? content.substr(0, this.limit).lastIndexOf(' ') 
        : this.limit;

    // Truncate the content to the adjusted limit
    let formattedContent = content.substr(0, adjustedLimit);

    // Append trailing characters if the content was truncated
    if (this.truncated) {
        formattedContent += this.trailingChars;
    }

    return formattedContent;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
//import { FaqService } from './services/faq.service';
import { NotFoundPageComponent } from './containers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { SlateModule } from 'slate-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CountUpModule } from 'ngx-countup';
import { NgxGalleryModule } from 'ngx-gallery-15';

import {
  //SocialLoginModule,
  //SocialAuthServiceConfig,
  //GoogleLoginProvider,
  //FacebookLoginProvider,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';

import {
  StarRatingComponent,
  ReviewComponent,
  SlateTextMarkComponent,
  SlateEditorComponent,
  SlateEditorToolbarItemComponent,
  PageBannerComponent,
} from './components';
import { PhonePipe, SafePipe } from './pipes';
import { ReviewListComponent } from './components/review-list/review-list.component';
import { ReviewFormComponent } from './components/review-form/review-form.component';
import { TriStateToggleComponent } from './components/tri-state-toggle/tri-state-toggle.component';
import { HeaderAvatarComponent } from './components/header-avatar/header-avatar.component';
import { CareCommunityRedirectComponent } from './components/care-community-redirect/care-community-redirect.component';
import { PayloadService } from './services/payload.service';
import { PayloadApiEndpointsService } from './services/payload-api-endpoints.service';
import { Error404Component } from './components/error-404/error-404.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderLinksComponent } from './components/header-links/header-links.component';
import { Error401Component } from './components/error-401/error-401.component';
import { Error403Component } from './components/error-403/error-403.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MaxsubstringPipe } from './pipes/maxsubstring.pipe';
import { FavButtonComponent } from './components/fav-button/fav-button.component';
import { BaseComponentComponent } from './components/base-component/base-component.component';
import { LazyLoadDirective } from './directives';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { TableModule } from 'ngx-easy-table';
import { EmailMaskPipe } from './pipes/email-mask.pipe';

@NgModule({
  declarations: [
    NotFoundPageComponent,
    PhonePipe,
    StarRatingComponent,
    ReviewComponent,
    SlateTextMarkComponent,
    SlateEditorComponent,
    SlateEditorToolbarItemComponent,
    SafePipe,
    ReviewListComponent,
    ReviewFormComponent,
    TriStateToggleComponent,
    PageBannerComponent,
    HeaderAvatarComponent,
    CareCommunityRedirectComponent,
    Error404Component,
    HeaderComponent,
    FooterComponent,
    HeaderLinksComponent,
    Error401Component,
    Error403Component,
    MaxsubstringPipe,
    FavButtonComponent,
    BaseComponentComponent,
    LazyLoadDirective,
    ReadMoreComponent,
    EmailMaskPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxJsonLdModule,
    NgbModule,
    NgSelectModule,
    GoogleMapsModule,
    NgxPageScrollModule,
    SlateModule,
    TableModule,
    //SocialLoginModule,
    GoogleSigninButtonModule,
    NgApexchartsModule,
    CountUpModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    NgxGalleryModule,
  ],
  exports: [
    // Modules
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxJsonLdModule,
    NgbModule,
    NgSelectModule,
    GoogleMapsModule,
    NgxPageScrollModule,
    SlateModule,
    NgApexchartsModule,
    CountUpModule,
    GoogleSigninButtonModule,
    NgCircleProgressModule,
    NgxGalleryModule,
    TableModule,
    // Components
    PageBannerComponent,
    NotFoundPageComponent,
    StarRatingComponent,
    ReviewComponent,
    ReviewListComponent,
    ReviewFormComponent,
    SlateTextMarkComponent,
    SlateEditorComponent,
    SlateEditorToolbarItemComponent,
    TriStateToggleComponent,
    HeaderAvatarComponent,
    CareCommunityRedirectComponent,
    HeaderComponent,
    FooterComponent,
    HeaderLinksComponent,
    FavButtonComponent,
    BaseComponentComponent,
    ReadMoreComponent,
    // Pipe
    PhonePipe,
    SafePipe,
    MaxsubstringPipe,
    EmailMaskPipe,
    // Directive
    LazyLoadDirective,
  ],
  providers: [
    /*{
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1027629411871-a413nbahafhmkq7id1og84fhpoqapvf7.apps.googleusercontent.com',
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },*/
  ],
})
export class SharedModule {}

<section *ngIf="promptFormat === 'lg'">
    <div class="auto-container">
        <div class="row align-items-center clearfix text-center mb-3 raffle-container">
            <h1>🎉 Florida Launch Giveaway! 🎉</h1>
            <div class="raffle-details">
                We’re celebrating our Florida launch by giving back to our amazing community with an ongoing giveaway
                from now until the end of July!<br />
                <p class="mt-3">
                    <a class="theme-btn-one small-scale me-5" data-bs-toggle="modal"
                        data-bs-target="#eligRuleModal">Eligibility Rules <i class="icon-Arrow-Right"></i></a>
                </p>
            </div>

        </div>
    </div>
</section>

<section *ngIf="promptFormat === 'banner-sm'">
    <div class="auto-container banner-sm">
        <div class="row align-items-center clearfix text-center raffle-container">
            <div class="col-12"><h6>🎉 Florida Launch Giveaway! 🎉</h6><a href="#" class="ms-3" data-bs-toggle="modal" data-bs-target="#eligRuleModal">Eligibility Rules <i class="icon-Arrow-Right"></i></a></div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="eligRuleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-fullscreen-md-down">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eligibility Rules</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="giveaway-details">
                    <p>We’re celebrating our Florida launch by giving back to our amazing community with an ongoing
                        giveaway from now until the end of July! 🎉</p>
                    <h4>Here’s what you can win:</h4>
                    <ul>
                        <li>$25 eGift Cards redeemable only at Walmart or Target.</li>
                        <li>
                            <span class="emphasize">Giveaway Period:</span><br />
                            Starts: 02/05/2025 11:59PM - Ends: 07/29/2025 11:59PM (EST)
                        </li>
                        <li class="mt-2">
                            Monthly winners announced on: Feb 29th, Apr 30th, Jun 30th, with the Grand Prize winner
                            drawn on July 31, 2025.</li>
                    </ul>

                    <h4>GRAND PRIZE 🏈:</h4>
                    <p>2 Tickets to a Miami Dolphins Preseason Game! (ARV: $200.00 - Parking, transportation, lodging,
                        merchandise, food & drinks not included.)</p>

                    <h4>How to Enter:</h4>
                    <ul>
                        <li>✅ Follow @yoursocialwellbeing</li>
                        <li>✅ Comment “Community” below & we’ll DM you the signup link!</li>
                        <li>✅ Create an account on Socialwellbeing.com</li>
                    </ul>

                    <h4>Winner Selection:</h4>
                    <p>All winners will be randomly drawn from our website signups using a third-party selector. If the
                        Grand Prize winner is unavailable, we’ll redraw a new winner. Tickets are nontransferable for
                        cash or other games. Game selection is at the discretion of Social Wellbeing.</p>
                </div>

                <div class="rules-section">
                    <h4>Official Rules for Social Wellbeing’s Florida Launch Giveaway</h4>
                    <p>
                        <strong class="emphasize mb-2">NO PURCHASE NECESSARY.</strong><br />This giveaway (the “Florida
                        Launch”) is sponsored by Social Wellbeing, located in Boca Raton, FL. By entering, you agree to
                        the following terms and conditions:
                    </p>

                    <ul>
                        <li><strong>1. ELIGIBILITY:</strong> Open only to Florida residents who are 18 years or older.
                            Employees, agents, and representatives of Social Wellbeing and affiliated entities are not
                            eligible to enter.</li>
                        <li><strong>2. GIVEAWAY PERIOD:</strong> The Giveaway starts on 02/05/2025 at 11:59pm and ends
                            on 07/29/2025 at 11:59pm (EST). Entries before or after this period will not be considered.
                        </li>
                        <li><strong>3. HOW TO ENTER:</strong> Follow the steps outlined above. You may also enter by
                            sending an email to info@socialwellbeing.com.</li>
                        <li><strong>4. PRIZES:</strong> Monthly Prizes: $25 eGift Card (redeemable only at Walmart or
                            Target). Grand Prize: 2 Miami Dolphins Preseason Game Tickets.</li>
                        <li><strong>5. WINNER SELECTION & NOTIFICATION:</strong> Winners will be selected by a random
                            drawing conducted by a third-party selection tool from all eligible
                            entries. Monthly Winners (February, April, June) will be notified via email within 3 days
                            following the end of
                            each month’s entry period. Grand Prize Winner will be drawn on July 31, 2025 and notified
                            via email.
                            If the winner does not respond within 48 hours of being contacted, or if they are unable to
                            claim the
                            prize, a new winner will be drawn. This process will continue up to three redraws until a
                            valid winner is
                            found. If no valid winner is confirmed after three redraws, the prize will be forfeited.
                        </li>
                        <li><strong>6. ALTERNATIVE ENTRY:</strong> As mentioned above, you can enter the Giveaway
                            without using Instagram by sending an email to
                            info@socialwellbeing.com with “Giveaway Entry” in the subject line and your full name, phone
                            number,
                            and Florida address, plus completion of step 3, website sign up.</li>
                        <li><strong>7. GENERAL CONDITIONS:</strong> All entries must be complete, accurate, and received
                            by the end of the Giveaway Period. By entering, you grant Social Wellbeing permission to use
                            your name, likeness, and entry for promotional
                            purposes in any media without further compensation, unless prohibited by law. Winners are
                            responsible for any taxes, fees, or other costs associated with accepting the prize.
                            The Giveaway is subject to all applicable local, state, and federal laws and regulations.
                            Void where
                            prohibited by law.</li>
                        <li><strong>8. LIMITATION OF LIABILITY:</strong> By entering the Giveaway, entrants agree to
                            release and hold harmless Social Wellbeing, its affiliates,
                            subsidiaries, advertising agencies, and their respective employees, agents, and
                            representatives from any
                            and all liability, loss, or damage resulting from participation in the Giveaway or the
                            acceptance, use, or
                            misuse of any prize.</li>
                        <li><strong>9. SOCIAL MEDIA DISCLAIMER:</strong> This Giveaway is in no way sponsored, endorsed,
                            or administered by Instagram, Meta, the Miami
                            Dolphins, Walmart, or Target. By entering, you agree to release Instagram, Meta, the Miami
                            Dolphins,
                            Walmart, and Target from any liability related to the Giveaway.</li>
                        <li><strong>10. GOVERNING LAW:</strong> This Giveaway is governed by the laws of the state of
                            Florida, without regard to its conflicts of law
                            principles. By entering the Giveaway, you acknowledge that you have read and agreed to the
                            above Official Rules.
                            Social Wellbeing reserves the right to amend or cancel the Giveaway at any time for any
                            reason,
                            including if fraud or technical failures compromise the fairness or integrity of the
                            Giveaway.</li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';
import { environment } from '../../../environments/environment';
import { interval, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private unleashClient: UnleashClient;

  constructor() {
    this.unleashClient = new UnleashClient({
      url: environment.unleash.url,
      clientKey: environment.unleash.clientKey,
      appName: `${environment.app.name}-${environment.app.stage}`,
      refreshInterval: 60,
      metricsInterval: 60
    });

    this.unleashClient.start();
  }

  isFeatureEnabled(featureName: string): boolean {
    return this.unleashClient.isEnabled(featureName);
  }

  onFeatureUpdate(callback: () => void): void {
    this.unleashClient.on('update', callback);
  }

  updateContext(mapping: any) {
    this.unleashClient.updateContext(mapping);
  }

  pollFeatureFlag(featureFlag: string, intervalSeconds: number) {
    return interval(intervalSeconds * 1000).pipe(
      map(() => ({
        flag: featureFlag,
        isEnabled: this.isFeatureEnabled(featureFlag),
      }))
    );
  }
}

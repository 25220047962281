import { Injectable } from '@angular/core';
import { join } from 'lodash';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PayloadApiEndpointsService {
  private usersSlug: string = environment.payload.users_slug;
  private mediaSlug: string = 'media'
  private careTeamSlug: string = 'careteam'
  private base: string = join([environment.api.url, 'api'], '/');

  constructor() {}

  private create(slug: string, path: string = '') {
    const segments = [this.base, slug, path].filter(x => x);
    return join(segments, '/');
  }

  public routes = {
    auth: {
      login: this.create(this.usersSlug, 'login'),
      me: this.create(this.usersSlug, 'me'),
      logout: this.create(this.usersSlug, 'logout'),
      forgotPassword: this.create(this.usersSlug, 'forgot-password'),
      resetPassword: this.create(this.usersSlug, 'reset-password'),
      refresh: this.create(this.usersSlug, 'refresh-token'),
    },
    user: {
      update: (userId: string) => `${this.create(this.usersSlug)}/${userId}`,
    },
    media: {
      post: this.create(this.mediaSlug)
    },
    careTeam: {
      get: (userId: string) => `${this.create('users')}/${userId}/careTeam`,
      post: (userId: string, providerId: string) => `${this.create(this.careTeamSlug)}/${userId}/provider/${providerId}`,
    }
  };
}

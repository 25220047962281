export interface MediaResult {
    message: string
    doc: Doc
  }
  
   interface Doc {
    id: string
    prefix: string
    filename: string
    mimeType: string
    filesize: number
    width: number
    height: number
    sizes: Sizes
    createdAt: string
    updatedAt: string
    url: string
  }
  
   interface Sizes {
    thumbnail: Thumbnail
    card: Card
    tablet: Tablet
  }
  
   interface Thumbnail {
    width: number
    height: number
    mimeType: string
    filesize: number
    filename: string
    url: string
  }
  
   interface Card {
    width: number
    height: number
    mimeType: string
    filesize: number
    filename: string
    url: string
  }
  
   interface Tablet {
    width: number
    height: number
    mimeType: string
    filesize: number
    filename: string
    url: string
  }
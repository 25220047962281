export interface IPerspectiveResponse {
    attributeScores: IAttributeScores
    languages: string[]
    detectedLanguages: string[]
    min: number
    max: number
  }
  
  export interface IAttributeScores {
    TOXICITY: IDetail
    INSULT: IDetail
    THREAT: IDetail
    IDENTITY_ATTACK: IDetail
    PROFANITY: IDetail
  }
  
  export interface IDetail {
    spanScores: ISpanScore[]
    summaryScore: ISummaryScore
  }
  
  export interface ISpanScore {
    begin: number
    end: number
    score: IScore
  }
  
  export interface IScore {
    value: number
    type: string
  }
  
  export interface ISummaryScore {
    value: number
    type: string
  }
  
  export interface IOmniInput {
    type: string, 
    text?: string,
    image_url?: {
      url?: string
    }
  }



  export interface IOpenAiModeratorResponse {
    id: string
    model: string
    results: IOpenAiModeratorResult[]
  }
  
  export interface IOpenAiModeratorResult {
    flagged: boolean
    categories: Categories
    category_scores: CategoryScores
    category_applied_input_types: CategoryAppliedInputTypes
  }
  
  export interface Categories {
    harassment: boolean
    "harassment/threatening": boolean
    sexual: boolean
    hate: boolean
    "hate/threatening": boolean
    illicit: boolean
    "illicit/violent": boolean
    "self-harm/intent": boolean
    "self-harm/instructions": boolean
    "self-harm": boolean
    "sexual/minors": boolean
    violence: boolean
    "violence/graphic": boolean
  }
  
  export interface CategoryScores {
    harassment: number
    "harassment/threatening": number
    sexual: number
    hate: number
    "hate/threatening": number
    illicit: number
    "illicit/violent": number
    "self-harm/intent": number
    "self-harm/instructions": number
    "self-harm": number
    "sexual/minors": number
    violence: number
    "violence/graphic": number
  }
  
  export interface CategoryAppliedInputTypes {
    harassment: any[]
    "harassment/threatening": any[]
    sexual: string[]
    hate: any[]
    "hate/threatening": any[]
    illicit: any[]
    "illicit/violent": any[]
    "self-harm/intent": string[]
    "self-harm/instructions": string[]
    "self-harm": string[]
    "sexual/minors": any[]
    violence: string[]
    "violence/graphic": string[]
  }
  
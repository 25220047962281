// @ts-nocheck
import { Component, Input } from '@angular/core';

@Component({
  selector: 'web-header-links',
  templateUrl: './header-links.component.html',
  styleUrls: ['./header-links.component.scss'],
})
export class HeaderLinksComponent {
  @Input() user: IUser | null | undefined = null;
  @Input() isLoggedIn: boolean = false

  rnd = Math.floor(Math.random() * 100000);

  toggleMobileMemuItem(el: Event, listId: string): void {
    const list = document.getElementById(listId);
    // @ts-nocheck
    const toggleClass = 'open';
    if(el.srcElement.classList.contains(toggleClass)) {
      el?.srcElement?.classList.remove(toggleClass);
      list.style.display = 'none';
    }
    else {
      el?.srcElement?.classList.add(toggleClass);

      list.style.display = 'block';
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptInjectorService {
  injectScript(src: string, attributes: Record<string, string>): void {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      console.log('Script already loaded:', src);
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    // Add custom attributes like data-tracking
    for (const [key, value] of Object.entries(attributes)) {
      script.setAttribute(key, value);
    }

    document.head.appendChild(script);
    console.log(`Script injected: ${src}`);
  }
}
export enum FEATURE_FLAGS  {
  PUB_SITE_ACCESS = 'PUB_SITE_ACCESS',
  PUB_SHOW_USER_JOURNAL = 'PUB_SHOW_USER_JOURNAL',
  PUB_PROVIDER_SIGNUP = 'PUB_PROVIDER_SIGNUP'
}

export const US_STATES: { [key: string]: string } = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming",
    "DC": "Washington D.C."
};

export const REDIS_KEYS = {
  discourse_categories: 'discourse/categories'
}

export const PROVIDER_TAXONOMIES = [
    {
      "MEDICARE_SPECIALTY_CODE": "1",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dentist - Orthodontics",
      "PROVIDER_TAXONOMY_CODE": "1223X0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Orthodontics and Dentofacial Orthopedics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "1",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "General Practice",
      "PROVIDER_TAXONOMY_CODE": "208D00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/General Practice"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "General Surgery",
      "PROVIDER_TAXONOMY_CODE": "208600000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "General Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0120X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Pediatric Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic and Reconstructive Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0122X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Plastic and Reconstructive Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "General Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0105X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Surgery of the Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "General Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Surgical Critical Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Surgical Oncology",
      "PROVIDER_TAXONOMY_CODE": "2086X0206X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Surgical Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Trauma Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0127X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Trauma Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Vascular Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0129X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Vascular Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Cardiothoracic Vascular Surgery",
      "PROVIDER_TAXONOMY_CODE": "208G00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Thoracic Surgery (Cardiothoracic Vascular Surgery)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Transplant Surgery",
      "PROVIDER_TAXONOMY_CODE": "204F00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Transplant Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Colon & Rectal Surgery",
      "PROVIDER_TAXONOMY_CODE": "208C00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Colon & Rectal Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neurological Surgery",
      "PROVIDER_TAXONOMY_CODE": "207T00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Neurological Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Oral & Maxillofacial Surgery",
      "PROVIDER_TAXONOMY_CODE": "204E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Oral & Maxillofacial Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physician/Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207X00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XS0114X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Adult Reconstructive Orthopedic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Foot and Ankle Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XS0106X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Hand Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XS0117X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Orthopedic Surgery of the Spine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0801X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Orthopedic Trauma"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XP3100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Pediatric Orthopedic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic Surgery",
      "PROVIDER_TAXONOMY_CODE": "208200000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic Surgery",
      "PROVIDER_TAXONOMY_CODE": "2082S0099X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery/Plastic Surgery Within the Head & Neck"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic Surgery",
      "PROVIDER_TAXONOMY_CODE": "2082S0105X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery/Surgery of the Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Allergy/ Immunology",
      "PROVIDER_TAXONOMY_CODE": "207K00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Allergy and Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Allergy/ Immunology",
      "PROVIDER_TAXONOMY_CODE": "207KA0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Allergy and Immunology/Allergy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinical & Laboratory Immunology",
      "PROVIDER_TAXONOMY_CODE": "207KI0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Allergy and Immunology/Clinical & Laboratory Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207Y00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Otolaryngology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YS0123X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Otolaryngology/Facial Plastic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YX0602X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Otolaryngic Allergy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YX0905X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Otolaryngology/Facial Plastic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YX0901X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Otology &Neurotology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YP0228X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Pediatric Otolaryngology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YX0007X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Plastic Surgery within the Head & Neck"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Otolaryngology",
      "PROVIDER_TAXONOMY_CODE": "207YS0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Anesthesiology",
      "PROVIDER_TAXONOMY_CODE": "207L00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "207LA0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology/Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Anesthesiology",
      "PROVIDER_TAXONOMY_CODE": "207LC0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology/Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Anesthesiology",
      "PROVIDER_TAXONOMY_CODE": "207LH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology/Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Anesthesiology",
      "PROVIDER_TAXONOMY_CODE": "207LP2900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology/Pain Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Anesthesiology",
      "PROVIDER_TAXONOMY_CODE": "207LP3000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology/Pediatric Anesthesiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "6",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Cardiology",
      "PROVIDER_TAXONOMY_CODE": "207RC0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Cardiovascular Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dermatology",
      "PROVIDER_TAXONOMY_CODE": "207N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Dermatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinical & Laboratory Dermatological Immunology",
      "PROVIDER_TAXONOMY_CODE": "207NI0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Dermatology, Clinical & Laboratory Dermatological Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dermatology",
      "PROVIDER_TAXONOMY_CODE": "207ND0101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Dermatology, MOHS-Micrographic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dermapathology",
      "PROVIDER_TAXONOMY_CODE": "207ND0900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Dermatology, Dermapathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Dermatology",
      "PROVIDER_TAXONOMY_CODE": "207NP0225X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Dermatology, Pediatric Dermatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dermatology",
      "PROVIDER_TAXONOMY_CODE": "207NS0135X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic &Osteopathic Physicians/Dermatology, Procedural Dermatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Family Medicine",
      "PROVIDER_TAXONOMY_CODE": "207Q00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QA0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Adolescent Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QA0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Adolescent Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Adult Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QA0505X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Adult Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Bariatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QB0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Bariatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Geriatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QG0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Geriatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QS0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QS1201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "9",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Interventional Pain Management",
      "PROVIDER_TAXONOMY_CODE": "208VP0014X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pain Medicine, Interventional Pain Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "10",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Gastroenterology",
      "PROVIDER_TAXONOMY_CODE": "207RG0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Gastroenterology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Internal Medicine",
      "PROVIDER_TAXONOMY_CODE": "207R00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RA0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Adolescent Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RA0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Adolescent Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Advanced Heart Failure and Transplant Cardiology",
      "PROVIDER_TAXONOMY_CODE": "207RA0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Advanced Heart Failure and Transplant Cardiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Allergy & Immunology",
      "PROVIDER_TAXONOMY_CODE": "207RA0201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Allergy & Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Bariatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RB0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Bariatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Cardiology",
      "PROVIDER_TAXONOMY_CODE": "207RC0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Cardiovascular Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinical & Laboratory Immunology",
      "PROVIDER_TAXONOMY_CODE": "207RI0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Clinical & Laboratory Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Critical Care Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RC0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Endocrinology",
      "PROVIDER_TAXONOMY_CODE": "207RE0101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Endocrinology, Diabetes, & Metabolism"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Gastroenterology",
      "PROVIDER_TAXONOMY_CODE": "207RG0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Gastroenterology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Geriatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RG0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Geriatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hematology",
      "PROVIDER_TAXONOMY_CODE": "207RH0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Hematology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hematology & Oncology",
      "PROVIDER_TAXONOMY_CODE": "207RH0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Hematology & Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hepatology",
      "PROVIDER_TAXONOMY_CODE": "207RI0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Hepatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Infectious Disease",
      "PROVIDER_TAXONOMY_CODE": "207RI0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Infectious Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Magnetic Resonance Imaging (MRI)",
      "PROVIDER_TAXONOMY_CODE": "207RM1200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Magnetic Resonance Imaging (MRI)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Oncology",
      "PROVIDER_TAXONOMY_CODE": "207RX0202X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Medical Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nephrology",
      "PROVIDER_TAXONOMY_CODE": "207RN0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Nephrology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pulmonary Disease",
      "PROVIDER_TAXONOMY_CODE": "207RP1001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Pulmonary Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Rheumatology",
      "PROVIDER_TAXONOMY_CODE": "207RR0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Rheumatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RS0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RS0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "11",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Transplant Hepatology",
      "PROVIDER_TAXONOMY_CODE": "207RT0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Transplant Hepatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "12",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuromusculoskeletal Medicine & OMM",
      "PROVIDER_TAXONOMY_CODE": "204D00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Neuromusculoskeletal Medicine & OMM"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "12",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "204C00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Neuromusculoskeletal Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "13",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neurology",
      "PROVIDER_TAXONOMY_CODE": "2084N0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology, Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "13",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neurology",
      "PROVIDER_TAXONOMY_CODE": "2084N0402X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology, Neurology with Special Qualifications in Child Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "13",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuroradiology",
      "PROVIDER_TAXONOMY_CODE": "2085N0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology/Neuroradiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "13",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychiatry and Neurology",
      "PROVIDER_TAXONOMY_CODE": "2084E0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology, Epilepsy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "13",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neurology",
      "PROVIDER_TAXONOMY_CODE": "2084A2900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Neurocritical Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "14",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neurosurgery",
      "PROVIDER_TAXONOMY_CODE": "207T00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Neurological Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "15",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Speech Language Pathologist",
      "PROVIDER_TAXONOMY_CODE": "235Z00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Speech, Language and Hearing Service Providers   "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207V00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VB0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Bariatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VC0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VF0040X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Female Pelvic Medicine and Reconstructive Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VX0201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Gynecologic Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VG0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Gynecology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VM0101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Maternal & Fetal Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VC0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Complex Family Planning"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VX0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Obstetrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "16",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obstetrics & Gynecology",
      "PROVIDER_TAXONOMY_CODE": "207VE0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Reproductive Endocrinology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "2086H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, General Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "207QH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, Family Practice"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "207LH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, Anesthesiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "207RH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, Internal Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "207VH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, Obstetrics & Gynecology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "2084H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, Neuropsychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "17",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice and Palliative Care",
      "PROVIDER_TAXONOMY_CODE": "207PH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine, Emergency Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207W00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0009X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology, Glaucoma Specialist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0107X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology, Retina Specialist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0108X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology, Uveitis and Ocular Inflammatory Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0109X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology/Neuro-ophthalmology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0110X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology/Pediatric Ophthalmology and Strabismus Specialist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0120X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology, Cornea and External Diseases Specialist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "18",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ophthalmology",
      "PROVIDER_TAXONOMY_CODE": "207WX0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmic Plastic and Reconstructive Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "19",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Oral & Maxillofacial Surgery",
      "PROVIDER_TAXONOMY_CODE": "1223S0112X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology, Dental Providers/Dentist, Oral & Maxillofacial Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207X00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XS0114X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Adult Reconstructive Orthopedic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Foot and Ankle Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XS0106X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Hand Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XS0117X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Orthopedic Surgery of the Spine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0801X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Orthopedic Trauma"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": " Pediatric Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XP3100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Pediatric Orthopedic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "20",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "21",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinical Cardiac Electrophysiology",
      "PROVIDER_TAXONOMY_CODE": "207RC0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Clinical Cardiatric Electrophysiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZP0101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Anatomic Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZP0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Anatomic Pathology & Clinical Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZB0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Blood Banking & Transfusion Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZP0104X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Chemical Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZC0006X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Clinical Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZP0105X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Clinical Pathology/Laboratory Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZC0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Cytopathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZD0900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Dermapathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZF0201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Forensic Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZH0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Hematology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZI0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Immunopathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZM0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Medical Microbiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZP0007X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Molecular Genetic Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZN0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Neuropathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZP0213X ",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology, Pediatric Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "22",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pathology",
      "PROVIDER_TAXONOMY_CODE": "207ZC0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pathology/Clinical Informatics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Orthopedic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207XX0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PS0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QS0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RS0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "204C00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Neuromusculoskeletal Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine & Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081P0301X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Brain Injury"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine & Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "23",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "2084S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "24",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic and Reconstructive Surgery",
      "PROVIDER_TAXONOMY_CODE": "208200000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "24",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic and Reconstructive Surgery",
      "PROVIDER_TAXONOMY_CODE": "2082S0099X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery, Plastic Surgery Within the Head and Neck"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "24",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic and Reconstructive Surgery",
      "PROVIDER_TAXONOMY_CODE": "2082S0105X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery, Surgery of the Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine and Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "208100000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine and Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine and Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081N0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Neuromuscular Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine and Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081P2900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Pain Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Rehabilitation Medicine",
      "PROVIDER_TAXONOMY_CODE": "2081P0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Pediatric Rehabilitation Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine and Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081P0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Spinal Cord Injury Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "25",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Medicine and Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "2081S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "26",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "26",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0301X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Respiratory, Developmental, Rehabilitative and Restorative Service , Brain Injury Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "27",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Geriatric Psychiatry",
      "PROVIDER_TAXONOMY_CODE": "207QG0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Geriatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "28",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Colorectal Surgery (Proctology)",
      "PROVIDER_TAXONOMY_CODE": "208C00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Colon & Rectal Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "29",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pulmonary Disease",
      "PROVIDER_TAXONOMY_CODE": "207RP1001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Pulmonary Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "30",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Diagnostic Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085R0202X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Diagnostic Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "30",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Diagnostic Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085R0205X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Radiological Physics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "30",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Diagnostic Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085U0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Diagnostic Ultrasound"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "30",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Diagnostic Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085D0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Diagnostic Neuroimaging"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "30",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085P0229X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Pediatric Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "31",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Intensive Cardiac Rehabilitation",
      "PROVIDER_TAXONOMY_CODE": "163WC3500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Nursing Service Providers, Registered Nurse, Cardiac Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "32",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Anesthesiology Assistant",
      "PROVIDER_TAXONOMY_CODE": "367H00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Anesthesiologist Assistant"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "33",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Thoracic Surgery",
      "PROVIDER_TAXONOMY_CODE": "208G00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Thoracic Surgery (Cardiothoracic Vascular Surgery)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "34",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Urology",
      "PROVIDER_TAXONOMY_CODE": "208800000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Urology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "34",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Urology",
      "PROVIDER_TAXONOMY_CODE": "2088P0231X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Urology, Pediatric Urology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "34",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Urology",
      "PROVIDER_TAXONOMY_CODE": "2088F0040X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Female Pelvic Medicine & Reconstructive Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Independent Medical Examiner",
      "PROVIDER_TAXONOMY_CODE": "111NI0013X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Independent Medical Examiner"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NI0900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Internist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NN0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NN1001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Nutrition"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NX0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Occupational Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NX0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Orthopedic"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NP0017X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Pediatric Chiropractor"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NR0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NR0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NS0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Sports Physician"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "35",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Chiropractor",
      "PROVIDER_TAXONOMY_CODE": "111NT0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Chiropractic Providers/Chiropractor, Thermography"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "36",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nuclear Medicine",
      "PROVIDER_TAXONOMY_CODE": "207U00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Nuclear Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "36",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nuclear Medicine",
      "PROVIDER_TAXONOMY_CODE": "2085N0904X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology/Neuroradiology/Nuclear Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "36",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nuclear Medicine",
      "PROVIDER_TAXONOMY_CODE": "207UN0903X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Nuclear Medicine, In Vivo & In Vitro Nuclear Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "36",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nuclear Medicine",
      "PROVIDER_TAXONOMY_CODE": "207UN0901X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Nuclear Medicine, Nuclear Cardiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "36",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nuclear Medicine",
      "PROVIDER_TAXONOMY_CODE": "207UN0902X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Nuclear Medicine, Nuclear Imaging & Therapy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "208000000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080A0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Adolescent Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080I0007X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Clinical & Laboratory Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0006X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Developmental–Behavioral Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080T0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Medical Toxicology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080N0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Neonatal-Perinatal Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Neurodevelopmental Disabilities"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Allergy & Immunology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0202X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Cardiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0203X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0204X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Emergency Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080P0205X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Endocrinology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Gastroenterology",
      "PROVIDER_TAXONOMY_CODE": "2080P0206X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Gastroenterology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Hematology-Oncology",
      "PROVIDER_TAXONOMY_CODE": "2080P0207X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Hematology-Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Infectious Diseases",
      "PROVIDER_TAXONOMY_CODE": "2080P0208X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Infectious Diseases"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Nephrology",
      "PROVIDER_TAXONOMY_CODE": "2080P0210X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Nephrology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Pulmonology",
      "PROVIDER_TAXONOMY_CODE": "2080P0214X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Pulmonology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Rheumatology",
      "PROVIDER_TAXONOMY_CODE": "2080P0216X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Rheumatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Transplant Hepatology",
      "PROVIDER_TAXONOMY_CODE": "2080T0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Pediatric Transplant Hepatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080S0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pediatric Obesity Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080B0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics/Obesity Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "37",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Child Abuse Pediatrics",
      "PROVIDER_TAXONOMY_CODE": "2080C0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics/Child Abuse Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "38",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Geriatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RG0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Geriatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "38",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Geriatric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QG0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Geriatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "39",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nephrology",
      "PROVIDER_TAXONOMY_CODE": "207RN0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Nephrology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "40",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hand Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0105X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery, Surgery of the Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "40",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Plastic Surgery",
      "PROVIDER_TAXONOMY_CODE": "2082S0105X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Plastic Surgery, Surgery of the Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152W00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152WC0802X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist, Corneal and Contact Management"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152WL0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist, Low Vision Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152WX0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist, Occupational Vision"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152WP0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152WS0006X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist, Sports Vision"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "41",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optometry",
      "PROVIDER_TAXONOMY_CODE": "152WV0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Service Providers/Optometrist, Vision Therapy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "42",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Nurse Midwife",
      "PROVIDER_TAXONOMY_CODE": "367A00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Midwife, Certified Nurse"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "43",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Registered Nurse Anesthetist (CRNA)",
      "PROVIDER_TAXONOMY_CODE": "367500000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Anesthetist, Certified Registered "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "44",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Infectious Disease",
      "PROVIDER_TAXONOMY_CODE": "207RI0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Infectious Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "45",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Mammography Center",
      "PROVIDER_TAXONOMY_CODE": "261QR0208X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic-Center, Radiology, Mammography"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "45",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Mammography Center",
      "PROVIDER_TAXONOMY_CODE": "261QR0207X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic-Center, Radiology, Mobile Mammography"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "46",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Endocrinology",
      "PROVIDER_TAXONOMY_CODE": "207RE0101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Endocrinology, Diabetes & Metabolism"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "47",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Independent Diagnostic Testing Facility (IDTF)",
      "PROVIDER_TAXONOMY_CODE": "293D00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Laboratories/Physiological Laboratory"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213ES0103X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, Foot & Ankle Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213ES0131X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, Foot Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213EG0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, General Practice"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213EP1101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, Primary Podiatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213EP0504X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, Public Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213ER0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "48",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Podiatry",
      "PROVIDER_TAXONOMY_CODE": "213ES0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Podiatric Medicine & Surgery Service Providers/Podiatrist, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "49",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ambulatory Surgical Center",
      "PROVIDER_TAXONOMY_CODE": "261QA1903X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic-Center, Ambulatory Surgical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363L00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LA2100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Acute Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LA2200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Adult Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LC1500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Community Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LC0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LF0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Family"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LG0600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Gerontology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LN0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Neonatal"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LN0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Neonatal, Critical Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LX0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Obstetrics & Gynecology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LX0106X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Occupational Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LP0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LP0222X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Pediatrics, Critical Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LP1700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Perinatal"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LP2300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Primary Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LP0808X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Psychiatric/Mental Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LS0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, School"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "50",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Nurse Practitioner",
      "PROVIDER_TAXONOMY_CODE": "363LW0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Nurse Practitioner, Women’s Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "51",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Orthotist",
      "PROVIDER_TAXONOMY_CODE": "335E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Prosthetic/Orthotic Supplier"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "52",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Prosthetist",
      "PROVIDER_TAXONOMY_CODE": "335E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Prosthetic/Orthotic Supplier"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "53",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Orthotist-Prosthetist",
      "PROVIDER_TAXONOMY_CODE": "335E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Prosthetic/Orthotic Supplier"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "54",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Other Medical Supply Company",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "55",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Orthotist",
      "PROVIDER_TAXONOMY_CODE": "222Z00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative, and Restorative Service Providers/Orthotist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "56",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Prosthetist",
      "PROVIDER_TAXONOMY_CODE": "224P00000X\n229N00000X\n",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative, and Restorative Service Providers/Prosthetist\nRespiratory, Developmental, Rehabilitative, and Restorative Service Providers/Anaplastologist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "57",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Prosthetist-Orthotist",
      "PROVIDER_TAXONOMY_CODE": "222Z00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative, and Restorative Service Providers/Orthotist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "57",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Prosthetist-Orthotist",
      "PROVIDER_TAXONOMY_CODE": "229N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative, and Restorative Service Providers/Anaplastologist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "57",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Prosthetist-Orthotist",
      "PROVIDER_TAXONOMY_CODE": "224P00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative, and Restorative Service Providers/Prosthetist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "333600000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336C0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Clinic Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336C0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Community/Retail Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336C0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Compounding Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336H0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Home Infusion Therapy Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336I0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Institutional Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336L0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Long-term Care Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336M0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Mail Order Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336M0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Managed Care Organization Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336N0007X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Nuclear Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "58",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Pharmacist",
      "PROVIDER_TAXONOMY_CODE": "3336S0011X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Specialty Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "59",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ambulance Service Provider",
      "PROVIDER_TAXONOMY_CODE": "341600000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Transportation Services/Ambulance"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "59",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ambulance Service Provider",
      "PROVIDER_TAXONOMY_CODE": "3416A0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Transportation Services/Ambulance, Air Transport"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "59",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ambulance Service Provider",
      "PROVIDER_TAXONOMY_CODE": "3416L0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Transportation Services/Ambulance, Land Transport"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "59",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ambulance Service Provider",
      "PROVIDER_TAXONOMY_CODE": "3416S0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Transportation Services/Ambulance, Water Transport"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "60",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Public Health or Welfare Agency",
      "PROVIDER_TAXONOMY_CODE": "251K00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Agencies/Public Health or Welfare"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "61",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Voluntary Health or Charitable Agency[1]",
      "PROVIDER_TAXONOMY_CODE": "251V00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Agencies/Voluntary or Charitable"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103T00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TA0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Addiction (Substance Abuse Disorder)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TA0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Adult Development & Aging"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TC0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Clinical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TC2200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Clinical Child & Adolescent"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TB0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Cognitive & Behavioral"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TC1900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Counseling"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TE1000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Educational"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TE1100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Exercise & Sports"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TF0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Family"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TF0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Forensic"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TP2701X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Group Psychotherapy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TH0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TH0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Health Service"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TM1700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Men & Masculinity"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TM1800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Intellectual & Developmental Disabilities "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TP0016X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Prescribing (Medical)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TP0814X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Psychoanalysis"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TP2700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Psychotherapy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TR0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TS0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, School"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TW0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Women"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "106E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Assistant Behavior Analyst"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "62",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "106S00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Technician"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "63",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Portable X-Ray Supplier",
      "PROVIDER_TAXONOMY_CODE": "335V00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Portable X-Ray Supplier"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "64",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Audiologist",
      "PROVIDER_TAXONOMY_CODE": "231H00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Speech, Language and Hearing Service Providers/Audiologist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "64",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Audiologist",
      "PROVIDER_TAXONOMY_CODE": "231HA2500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Speech, Language and Hearing Service Providers/Audiologist/Assistive Technology Supplier"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "64",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Audiologist",
      "PROVIDER_TAXONOMY_CODE": "231HA2400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Speech, Language and Hearing Service Providers/Audiologist, Assistive Technology Practitioner"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "225100000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251C2600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Cardiopulmonary"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251E1300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Electrophysiology, Clinical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251E1200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Ergonomics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251G0304X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Geriatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251H1200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251H1300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Human Factors"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251N0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251X0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Orthopedic"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251P0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Physical Therapist, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "65",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physical Therapy",
      "PROVIDER_TAXONOMY_CODE": "2251S0007X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Rehabilitative & Restorative Service Providers/Physical Therapist, Sports"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "66",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Rheumatology",
      "PROVIDER_TAXONOMY_CODE": "207RR0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Rheumatology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225X00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XR0403X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Driving and Community Mobility"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XE0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Environmental Modification"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XE1200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Ergonomics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XF0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Feeding, Eating &Swallowing"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XG0600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Gerontology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XH1200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Hand"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XH1300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Human Factors"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XL0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Low Vision"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XM0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Mental Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XN1300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Neurorehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XP0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "67",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Therapy",
      "PROVIDER_TAXONOMY_CODE": "225XP0019X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative & Restorative Service Providers/Occupational Therapist, Physical Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "68",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Psychologist, Clinical",
      "PROVIDER_TAXONOMY_CODE": "103TC0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Psychologist, Clinical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "69",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinical Laboratory",
      "PROVIDER_TAXONOMY_CODE": "291U00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Laboratories/Clinical Medical Laboratory"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "70",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinic or Group Practice",
      "PROVIDER_TAXONOMY_CODE": "261QM1300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Multi-Specialty  "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "70",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinic or Group Practice",
      "PROVIDER_TAXONOMY_CODE": "261QP2000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Physical Therapy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "70",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinic or Group Practice",
      "PROVIDER_TAXONOMY_CODE": "193200000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Group/Multi-Specialty"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "70",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinic or Group Practice",
      "PROVIDER_TAXONOMY_CODE": "261Q00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Multi-Specialty  "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "70",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Clinic or Group Practice",
      "PROVIDER_TAXONOMY_CODE": "193400000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Group/Single-Specialty"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133V00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1006X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Metabolic"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Pediatric"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Renal"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Gerontological"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Obesity and Weight Management"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1301X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Pediatric Critical Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "71",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Registered Dietitian",
      "PROVIDER_TAXONOMY_CODE": "133VN1501X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Dietary & Nutritional Service Providers/Dietician, Registered, Nutrition, Sports Dietetics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "72",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pain Management",
      "PROVIDER_TAXONOMY_CODE": "208VP0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pain Medicine, Pain Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "73",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Mass Immunizer Roster Biller[2]",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "74",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Radiation Therapy Center",
      "PROVIDER_TAXONOMY_CODE": "261QR0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "75",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Slide Preparation Facility",
      "PROVIDER_TAXONOMY_CODE": "247200000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Technologists, Technicians & Other Technical Service Providers/Technician, Other"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "76",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Vascular Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0129X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery, Peripheral Vascular Disease"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "77",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Vascular Surgery",
      "PROVIDER_TAXONOMY_CODE": "2086S0129X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery, Vascular Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "78",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Cardiac Surgery",
      "PROVIDER_TAXONOMY_CODE": "208G00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Thoracic Surgery (Cardiothoracic Vascular Surgery)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "79",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "207L00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Anesthesiology, Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "79",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QA0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "79",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RA0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "79",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "2084A0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology, Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "80",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Licensed Clinical Social Worker",
      "PROVIDER_TAXONOMY_CODE": "1041C0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Behavioral Health & Social Service Providers/Social Worker, Clinical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "81",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Critical Care (Intensivists)",
      "PROVIDER_TAXONOMY_CODE": "207RC0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "82",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hematology",
      "PROVIDER_TAXONOMY_CODE": "207RH0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Hematology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "83",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hematology-Oncology",
      "PROVIDER_TAXONOMY_CODE": "207RH0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Hematology & Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Aerospace Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083A0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Aerospace Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Toxicology",
      "PROVIDER_TAXONOMY_CODE": "2083T0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Medical Toxicology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083X0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Occupational Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Occupational Environmental Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083P0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine,  Preventive Medicine/Occupational Environmental Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Public Health & General Preventive Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083P0901X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Public Health & General Preventive Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sports Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Undersea and Hyperbaric Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083P0011X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine, Undersea and Hyperbaric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Addiction Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083A0300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine/Addiction Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Obesity Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083B0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine/Obesity Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "84",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physician/Preventive Medicine",
      "PROVIDER_TAXONOMY_CODE": "2083C0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Preventive Medicine/Clinical Informatics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "85",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Maxillofacial Surgery",
      "PROVIDER_TAXONOMY_CODE": "204E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Oral and Maxillofacial Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084A0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0802X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Addiction Psychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084B0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Bariatric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084B0040X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Behavioral Neurology & Neuropsychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0804X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Child & Adolescent Psychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084N0600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Clinical Neurophysiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084D0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Diagnostic Neuroimaging"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084F0202X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Forensic Psychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0805X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Geriatric Psychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Hospice & Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Neurodevelopmental Disabilities"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084N0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084N0402X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Neurology with Special Qualifications in Child Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084N0008X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Neuromuscular Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P2900X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Pain Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Psychiatry"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084P0015X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Psychosomatic Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084S0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084S0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "86",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Neuropsychiatry",
      "PROVIDER_TAXONOMY_CODE": "2084V0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Vascular Neurology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "87[3]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "All Other Suppliers",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "88",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Unknown Supplier/Provider Specialty[4]",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364S00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SA2100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Acute Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SA2200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Adult Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SC2300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Chronic Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SC1501X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Community Health/Public Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SC0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Critical Care Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SE0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Emergency"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SE1400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Ethics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SF0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Family Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SG0600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Gerontology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SH1100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Holistic"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SH0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Home Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SI0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Informatics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SL0600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Long-term Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SM0705X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Medical-Surgical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SN0000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Neonatal"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SN0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Neuroscience"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SX0106X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Occupational Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SX0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SX0204X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Oncology, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP1700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Perinatal"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP2800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Perioperative"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0808X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0809X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health, Adult"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0807X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health, Child & Adolescent"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0810X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health, Child & Family"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0811X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health, Chronically Ill"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0812X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health, Community"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SP0813X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Psychiatric/Mental Health, Geropsychiatric"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SR0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SS0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, School"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364ST0500X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Transplantation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "89",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Certified Clinical Nurse Specialist",
      "PROVIDER_TAXONOMY_CODE": "364SW0102X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Clinical Nurse Specialist, Women’s Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "90",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Oncology",
      "PROVIDER_TAXONOMY_CODE": "207RX0202X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Medical Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "91",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Surgical Oncology",
      "PROVIDER_TAXONOMY_CODE": "2086X0206X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Surgery, Surgical Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "92",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Radiation Oncology",
      "PROVIDER_TAXONOMY_CODE": "2085R0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Radiation Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207P00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PE0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Emergency Medical Services"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PH0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Hospice and Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PT0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Medical Toxicology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PP0204X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Pediatric Emergency Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PS0010X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Sports Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "93",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Emergency Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PE0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Undersea and Hyperbaric Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "94",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Interventional Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085R0204X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Radiology, Vascular and Interventional Radiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "94",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Interventional Radiology",
      "PROVIDER_TAXONOMY_CODE": "2085H0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospice & Palliative Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "95",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Advance Diagnostic Imaging",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "96[5]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Optician",
      "PROVIDER_TAXONOMY_CODE": "156FX1800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye & Vision Service Providers/Technician/Technologist, Optician"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "97",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physician Assistant",
      "PROVIDER_TAXONOMY_CODE": "363A00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Physician Assistant"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "97",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physician Assistant",
      "PROVIDER_TAXONOMY_CODE": "363AM0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Physician Assistant, Medical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "97",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Physician Assistant",
      "PROVIDER_TAXONOMY_CODE": "363AS0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Physician Assistants & Advanced Practice Nursing Providers/Physician Assistant, Surgical"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "98",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Gynecological Oncology",
      "PROVIDER_TAXONOMY_CODE": "207VX0201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology, Gynecologic Oncology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "99",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "General Practice",
      "PROVIDER_TAXONOMY_CODE": "208D00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/General Practice"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-General",
      "PROVIDER_TAXONOMY_CODE": "282N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Acute Care",
      "PROVIDER_TAXONOMY_CODE": "282N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Children’s (PPS excluded)",
      "PROVIDER_TAXONOMY_CODE": "282NC2000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital, Children"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Long-Term (PPS excluded)",
      "PROVIDER_TAXONOMY_CODE": "282E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Long Term Care Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Psychiatric (PPS excluded)",
      "PROVIDER_TAXONOMY_CODE": "283Q00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Psychiatric Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Rehabilitation (PPS excluded)",
      "PROVIDER_TAXONOMY_CODE": "283X00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Rehabilitation Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Short-Term (General and Specialty)",
      "PROVIDER_TAXONOMY_CODE": "282N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital Units",
      "PROVIDER_TAXONOMY_CODE": "273100000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospital Units/Epilepsy Unit"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital Units",
      "PROVIDER_TAXONOMY_CODE": "276400000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospital Units/Rehabilitation/Substance Use Disorder Unit"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "281P00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Chronic Disease Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "281PC2000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Chronic Disease Hospital/Children"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "282NR1301X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital/Rural"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "282NW0100X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital/Women"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "283XC2000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital/Children"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "286500000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Military Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "2865M2000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Military Hospital/Military General Acute Care Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitals",
      "PROVIDER_TAXONOMY_CODE": "2865X1600X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Military Hospital/Military General Acute Care Hospital. Operational (Transportable)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Swing Bed Approved",
      "PROVIDER_TAXONOMY_CODE": "275N00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospital Units/Medicare Defined Swing Bed Unit"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Psychiatric Unit",
      "PROVIDER_TAXONOMY_CODE": "273R00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospital Units/Psychiatric Unit"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Rehabilitation Unit",
      "PROVIDER_TAXONOMY_CODE": "273Y00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospital Units/Rehabilitation Unit"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospital-Specialty Hospital (cardiac, orthopedic, surgical)",
      "PROVIDER_TAXONOMY_CODE": "284300000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Special(ty) Hospital"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A0[7]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Critical Access Hospital",
      "PROVIDER_TAXONOMY_CODE": "282NC0060X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/General Acute Care Hospital, Critical Access"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A1[8]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Skilled Nursing Facility",
      "PROVIDER_TAXONOMY_CODE": "314000000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Nursing and Custodial Care Facilities/Skilled Nursing Facility"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A1[8]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Skilled Nursing Facility",
      "PROVIDER_TAXONOMY_CODE": "3140N1450X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Nursing & Custodial Care Facilities/Skilled Nursing Facility/Nursing Care, Pediatric"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A2[9]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Intermediate Care Nursing Facility",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A2[9]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Intermediate Care Nursing Facility",
      "PROVIDER_TAXONOMY_CODE": "315P00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Nursing & Custodial Care Facilities/Intermediate Care Facility, Mentally Retarded"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A3[10]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Other Nursing Facility",
      "PROVIDER_TAXONOMY_CODE": "313M00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Nursing and Custodial Care Facilities/Nursing Facility"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A4[11]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Home Health Agency",
      "PROVIDER_TAXONOMY_CODE": "251E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Agencies/Home Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A4[11]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Home Health Agency (Subunit)",
      "PROVIDER_TAXONOMY_CODE": "251E00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Agencies/Home Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "333600000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336C0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Clinic Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336C0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Community/Retail Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336C0004X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Compounding Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336H0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Home Infusion Therapy Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336I0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Institutional Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336L0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Long-term Care Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336M0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Mail Order Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336M0003X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Managed Care Organization Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336N0007X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Nuclear Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "3336S0011X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Specialty Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "1835C0205X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Critical Care"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pharmacy",
      "PROVIDER_TAXONOMY_CODE": "1835P0200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Pharmacy, Pediatrics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A6",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Supply Company with Respiratory Therapist",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Department Store",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Grocery Store",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "A9[12]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Indian Health Service facility[13]",
      "PROVIDER_TAXONOMY_CODE": "332800000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B1",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Oxygen supplier",
      "PROVIDER_TAXONOMY_CODE": "332BX2000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies, Oxygen Equipment & Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pedorthic personnel",
      "PROVIDER_TAXONOMY_CODE": "222Z00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative and Restorative Service Providers/Orthotist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Pedorthic personnel",
      "PROVIDER_TAXONOMY_CODE": "224P00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Respiratory, Developmental, Rehabilitative and Restorative Service Providers/Prosthetist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical supply company with pedorthic personnel",
      "PROVIDER_TAXONOMY_CODE": "332B00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Durable Medical Equipment & Medical Supplies"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Rehabilitation Agency",
      "PROVIDER_TAXONOMY_CODE": "261QR0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Organ Procurement Organization",
      "PROVIDER_TAXONOMY_CODE": "335U00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers/Organ Procurement Organization"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Community Mental Health Center",
      "PROVIDER_TAXONOMY_CODE": "261QM0801X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Mental Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Comprehensive Outpatient Rehabilitation Facility",
      "PROVIDER_TAXONOMY_CODE": "261QR0401X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "End-Stage Renal Disease Facility",
      "PROVIDER_TAXONOMY_CODE": "261QE0700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/End-Stage Renal Disease (ESRD) Treatment"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Federally Qualified Health Center",
      "PROVIDER_TAXONOMY_CODE": "261QF0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Federally Qualified Health Center (FQHC)"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospice",
      "PROVIDER_TAXONOMY_CODE": "251G00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Agencies/Hospice Care, Community Based"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Histocompatibility Laboratory",
      "PROVIDER_TAXONOMY_CODE": "291U00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Laboratories/Clinical Medical Laboratory"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Histocompatibility Laboratory",
      "PROVIDER_TAXONOMY_CODE": "291900000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Laboratories/Military Clinical Medical Laboratory"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Outpatient Physical Therapy/Occupational Therapy/Speech Pathology Services",
      "PROVIDER_TAXONOMY_CODE": "261QR0400X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Rehabilitation Agency",
      "PROVIDER_TAXONOMY_CODE": "315D00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Nursing & Custodial Care Facilities/Hospice, Inpatient"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Religious Non-Medical Health Care Institution",
      "PROVIDER_TAXONOMY_CODE": "282J00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Hospitals/Religious Non-medical Health Care Institution"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B4[14]",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Rural Health Clinic",
      "PROVIDER_TAXONOMY_CODE": "261QR1300X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Rural Health"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "B5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Ocularist",
      "PROVIDER_TAXONOMY_CODE": "156FX1700X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Eye and Vision Services Providers, Technician/Technologist, Ocularist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C0",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "207QS1201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Family Medicine, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C0",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "207RS0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C0",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "207YS0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Otolaryngology/Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C0",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "2080S0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Pediatrics, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C0",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "2084S0012X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Psychiatry & Neurology, Sleep Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C0",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Sleep Medicine",
      "PROVIDER_TAXONOMY_CODE": "261QS1200X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ambulatory Health Care Facilities/Sleep Disorder Diagnostic"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Interventional Cardiology",
      "PROVIDER_TAXONOMY_CODE": "207RI0011X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Internal Medicine, Interventional Cardiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dentist",
      "PROVIDER_TAXONOMY_CODE": "122300000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Ophthalmology, Dental Providers/Dentist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Dentist",
      "PROVIDER_TAXONOMY_CODE": "1223G0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/General Practice"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C6",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hospitalist",
      "PROVIDER_TAXONOMY_CODE": "208M00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Hospitalist"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Advanced Heart Failure and Transplant Cardiology",
      "PROVIDER_TAXONOMY_CODE": "207RA0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Advanced Heart Failure and Transplant Cardiology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Toxicology",
      "PROVIDER_TAXONOMY_CODE": "207PT0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Emergency Medicine, Medical Toxicology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "C9",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Hematopoietic Cell Transplantation and Cellular Therapy",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D1",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medicare Diabetes Preventive Program",
      "PROVIDER_TAXONOMY_CODE": "174H00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Other Service Providers, Health Educator"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Genetics and Genomics",
      "PROVIDER_TAXONOMY_CODE": "207SG0201X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Clinical Genetics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Genetics and Genomics",
      "PROVIDER_TAXONOMY_CODE": "207SG0202X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians, Medical Genetics/Clinical Biochemical Genetics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Genetics and Genomics",
      "PROVIDER_TAXONOMY_CODE": "207SG0203X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/ Medical Genetics/Clinical Molecular Genetics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Genetics and Genomics",
      "PROVIDER_TAXONOMY_CODE": "207SG0205X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Medical Genetics/Ph.D. Medical Genetics"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D3",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Medical Genetics and Genomics",
      "PROVIDER_TAXONOMY_CODE": "207SM0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Medical Genetics/Molecular Genetic Pathology"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D4",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Undersea and Hyperbaric Medicine",
      "PROVIDER_TAXONOMY_CODE": "207PE0005X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians/Undersea and Hyperbaric Medicine "
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D5",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Opioid Treatment Program",
      "PROVIDER_TAXONOMY_CODE": "261QR0405X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Ambulatory Health Care Facilities/Clinic/Center, Rehabilitation"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D6",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Home Infusion Therapy Services",
      "PROVIDER_TAXONOMY_CODE": "3336H0001X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Suppliers, Pharmacy, Home Infusion Therapy Services"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D7",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Micrographic Dermatologic Surgery",
      "PROVIDER_TAXONOMY_CODE": "207ND0101X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians, Dermatology, MOHS-Micrographic Surgery"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "D8",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Adult Congenital Heart Disease",
      "PROVIDER_TAXONOMY_CODE": "207RA0002X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": "Allopathic & Osteopathic Physicians, Internal Medicine"
    },
    {
      "MEDICARE_SPECIALTY_CODE": "E1",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Marriage and Family Therapist",
      "PROVIDER_TAXONOMY_CODE": "106H00000X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "E2",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "Mental Health Counselor",
      "PROVIDER_TAXONOMY_CODE": "101YM0800X",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[1] Medicare no longer enrolls this provider type.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[2] This is an arrangement whereby immunization services are rendered by health care providers who are under contract to the entity/individual who is enrolled in Medicare as a Mass Immunization Roster Biller. The Healthcare PROVIDER_TAXONOMY_CODE(s) would be that (those) of the health care provider(s) who render the immunization services. ",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[3] Medicare carriers use Specialty Code 87 for Pharmacies when processing prescription drugs that are paid through the Medicare Physician Fee Schedule.  However, the description for Specialty Code 87 remains “All Other Suppliers.”",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[4] Because the Medicare Provider Type “Physician/Undefined Physician Type” applies to physicians, “Unknown Supplier/Provider Specialty” applies to other than physicians.  Because Medicare does not enroll every non-physician supplier/provider type in the Healthcare PROVIDER_TAXONOMY_CODE Set, we cannot arbitrarily link this Medicare Provider Type to all remaining supplier/provider types in the Healthcare PROVIDER_TAXONOMY_CODE Set.  In truth, this Medicare Provider Type would link to every Healthcare PROVIDER_TAXONOMY_CODE (except those that describe physicians) that is listed in this document.  We do not list them here because doing so would greatly increase the size of this document.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[5] Because the Medicare Provider Type indicates that the provider/supplier is a Physician, we have linked this to the most generic Allopathic & Osteopathic Physicians classification.  It could technically link to every Allopathic & Osteopathic Physician.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[6] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[7] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[8] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[9] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[10] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[11] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[12] There is no linkage to specific Healthcare PROVIDER_TAXONOMY_CODEs because an Indian Health Service facility can be various provider types/classifications.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[13] MEDICARE_SPECIALTY_CODE designation is for Part B DMEPOS purposes.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    },
    {
      "MEDICARE_SPECIALTY_CODE": "[14] Does not meet the definition of “health care provider” at 45 CFR 160.103; is not eligible for an NPI.",
      "MEDICARE_SUPPLIER_TYPE_DESCRIPTION": "",
      "PROVIDER_TAXONOMY_CODE": "",
      "PROVIDER_TAXONOMY_SPECIALIZATION": ""
    }
];

export const PROVIDER_PLANS = {
  elite: ['sw-provider-elite'],
  adv: ['sw-provider-adv'],
  free: ['sw-provider-free']
}
<div *ngIf="!isLoggedIn" class="btn-box">
    <!--<a [routerLink]="['/auth/login']"><button type="button">Login</button></a>-->
    <a [routerLink]="['/auth/login']" class="theme-btn-one">{{forum.label}}<i [class]="forum.icon"></i></a>
</div>

<div *ngIf="isLoggedIn" class="btn-box">
    <div class="author-box">
        <!--<div class="icon-box"><a href="index.html"><i class="icon-Bell"></i></a></div>-->
        <div class="author">
            <!--<figure class="author-image"><img src="assets/images/resource/author-1.png" alt=""></figure>-->
            <nav class="main-menu navbar-expand-md navbar-light">
                <ul class="navigation clearfix">
                    <li class="dropdown"><a>My Account</a>
                        <ul>
                            <li style="color: white;">
                                {{user?.username}}<br/>
                                {{user?.email | emailMask}}<br/>
                                <sup>Role: {{user?.roles?.join()}}</sup><br/>
                                <sup>Logged In</sup>
                            </li>
                            <li><a [routerLink]="['/my/review-journal']" class="nav-link"><i class='bx bx-list-ul'></i><span>My Review Journal</span></a></li>
                            <li><a [routerLink]="['/my/care-team']" class="nav-link"><i class='bx bx-plus-medical'></i><span>My Care Team</span></a></li>
                            <li><a [routerLink]="['/auth/logout']" class="nav-link"><i class='bx bx-log-out'></i><span>Logout</span></a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
            
        </div>
    </div>
</div>
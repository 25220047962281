import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    Input,
  } from '@angular/core';
  
  @Directive({
    selector: 'img[appLazyLoad]',
  })
  export class LazyLoadDirective implements AfterViewInit {
    @HostBinding('attr.src') srcAttr: string | null = null;
    @Input() src: string;
  
    constructor(private el: ElementRef) {}
  
    ngAfterViewInit() {
      this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
    }
  
    private canLazyLoad() {
      return window && 'IntersectionObserver' in window;
    }
  
    private lazyLoadImage() {
      const obs = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) {
            this.loadImage();
            obs.unobserve(this.el.nativeElement);
          }
        });
      });
      obs.observe(this.el.nativeElement);
    }
  
    private loadImage() {
      this.srcAttr = this.src;
    }
  }
  
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { WebAnalyticDataPoint, WebAnalyticPageViews, WebAnalyticEventData, WebAnalyticStats } from '../models';
@Injectable({
  providedIn: 'root'
})
export class WebAnalyticsService {

  API_URL = `${environment.api.url}/api`
  
  constructor(private http: HttpClient) {}

  triggerPostNaviProcess() {
    const intervals = [500, 1000, 500];
    intervals.forEach(interval => setTimeout(this.appendTrackingAttribute, interval));
  }

  private appendTrackingAttribute() {
    // Select all button and anchor elements
    const elements = document.querySelectorAll('button, a, [data-bs-toggle="collapse"]');
    const attributeName = 'data-umami-event';

    // Iterate over each element
    elements.forEach(element => {

      // Check if the element already has the data attribute
      if (!element.hasAttribute(attributeName)) {
        let text: string = '';

        if (element.hasAttribute('data-bs-toggle')) text = element.getAttribute('data-bs-target') || '';
        else if (element.classList.contains('page-link') && element.textContent) {
          text = `page-${element.textContent || ''}`;
          element.setAttribute("href", "javascript:void(0);");
        }
        else text = element.textContent || '';

        text = text?.replace(/[^a-zA-Z0-9\s-]/g, '').replace(/\s+/g, '-').toLowerCase();

        if (text) {
          // Create and set the data attribute
          const attributeValue = 'btn-' + text + '-click';
          element.setAttribute(attributeName, attributeValue);
        }
      }
    });
  }

  getActive(path: string, params: any): Observable<any> {
    return this.http.get<any>(`${this.API_URL}${path}/analytics/active`, { params })
      .pipe(
        map(res => res.payload)
      );
  }

  getEvents(path: string, params: any): Observable<WebAnalyticEventData[]> {
    return this.http.get<any>(`${this.API_URL}${path}/analytics/events`, { params })
    .pipe(
      map(res => res.payload)
    );
  }

  getPageviews(path: string,params: any): Observable<WebAnalyticPageViews> {
    return this.http.get<any>(`${this.API_URL}${path}/analytics/pageviews`, { params })
      .pipe(
        map(res => res.payload)
      );
  }

  getMetrics(path: string,  params: any): Observable<WebAnalyticDataPoint[]> {
    return this.http.get<any>(`${this.API_URL}${path}/analytics/metrics`, { params })
      .pipe(
        map(res => res.payload)
      );
  }

  getStats(path: string, params: any): Observable<WebAnalyticStats> {
    return this.http.get<any>(`${this.API_URL}${path}/analytics/stats`, { params })
      .pipe(
        map(res => res.payload)
      );
  }

 formatDuration(ms) {
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;

    let remainingMs = ms;

    const days = Math.floor(remainingMs / msInDay);
    remainingMs %= msInDay;

    const hours = Math.floor(remainingMs / msInHour);
    remainingMs %= msInHour;

    const minutes = Math.floor(remainingMs / msInMinute);
    remainingMs %= msInMinute;

    const seconds = Math.floor(remainingMs / msInSecond);

    const formattedDuration = [
        days > 0 ? `${days}d` : '',
        hours > 0 ? `${hours}h` : '',
        minutes > 0 ? `${minutes}m` : '',
        seconds > 0 ? `${seconds}s` : ''
    ].filter(Boolean).join(' ');

    return formattedDuration || '0s';
}
}

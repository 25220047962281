import { Component, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, inject } from '@angular/core';
import { IReview, IReviewJournal, IReviewSearchResult } from '../../models/review.model';
import { IProvider } from '../../models/provider.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReviewService } from '../../services';
import { BaseComponentComponent } from '../base-component/base-component.component';
import { takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'web-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss'],
})
export class ReviewListComponent extends BaseComponentComponent implements OnChanges {

  @Input() reviewsSearchResult: IReviewSearchResult;
  @Input() provider: IProvider;
  @Input() target: IProvider | IReview;
  @Input() mode: 'public' | 'owner' | 'provider' = 'public';

  @ViewChild('journalModal') private reviewJournalModal;

  isProvider = false;
  isReview = false;
  targetCollection: 'providers' | 'reviews' | 'posts';
  reviewJournalModalRef: NgbModalRef;
  reviewJournal: IReviewJournal;

  constructor(
    private modalService: NgbModal,
    private reviewService: ReviewService, 
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const sso: string = params['sso'];
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['target']?.currentValue) {
      // @ts-ignore
      this.isProvider = this.target?.cmsMeta ? true : false;

      // @ts-ignore
      this.isReview = this.target?.replyPost ? true : false;

      if (this.isProvider) this.targetCollection = 'providers';
      if (this.isReview) this.targetCollection = 'reviews';
    }
  }

  viewJournal($event: IReview) {
    if ($event.journalId) {
      this.reviewService.getJournal($event.journalId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(x => {
          this.reviewJournal = x;
          this.openReviewJournal();

        });
    }
  }


  openReviewJournal() {
    this.reviewJournalModalRef = this.modalService.open(this.reviewJournalModal, { fullscreen: 'md', size: 'xl', scrollable: true });
  }
}

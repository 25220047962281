import { IBaseSearchCriteria } from "./base-search-criteria.model"

export interface IInsuranceSearchCriteria extends IBaseSearchCriteria {

}

export interface IInsuranceSearchResult {
    docs: IInsurance[]
    totalDocs: number
    limit: number
    totalPages: number
    page: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prevPage: number | null
    nextPage: number
  }
  
  export interface IInsurance {
    id: string
    group: string
    name: string
    website: string
    imageUrl: string
    sortPriority: number
    title: string
  }

  export interface IInsuranceCount extends IInsurance {
    count: number,
    maxDate: Date
  }
  
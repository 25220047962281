import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DEFAULT_OPT_BINARY_FEEDBACK, IReview, ISurveyMeta } from '../../models/review.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Element } from 'slate';
import { IInsuranceSearchResult, IOpenAiModeratorResult, IPerspectiveResponse, IProvider } from '../../models';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, InsuranceService, ModeratorService } from '../../services';
import { debounceTime, firstValueFrom } from 'rxjs';
import { IUser } from '@sw-cms/shared-types';

@Component({
  selector: 'web-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss'],
})
export class ReviewFormComponent implements OnInit, OnChanges {
  @Input() context: 'review' | 'comment';
  @Input() target: IProvider | IReview;
  @Input() isLoggedIn: boolean = false;
  @Output() formStatusChangeEvent = new EventEmitter<boolean>();
  @Output() formChangeEvent = new EventEmitter<FormGroup>();

  @ViewChild('reviewFormModal') private modalContent!: TemplateRef<ReviewFormComponent>

  private modalRef!: NgbModalRef;

  
  isProviderTarget = false;
  isReviewTarget = false;
  targetCollection: 'providers' | 'reviews' | 'posts';
  imagePreviewUrls: {dataUrl?: string, moderatorResult?: string[], maxCategoryMapping?: {maxCategory: string, maxScore: number}}[] = [];
  maxNumOfImages: number = 3;
  lastAutoTxtModeratorResult: IPerspectiveResponse | null = null;
  autoTxtModeratorThresold: number = 0.6;
  autoImgModeratorThresold: number = 0.6;
  autoTxtStatus: { msg: string, isCompliant: boolean };
  isModerating: boolean = false;
  insuranceResult: IInsuranceSearchResult;
  compliance_positive: string = 'Complies with community guidelines';
  compliance_negative: string = 'Violates community guidelines. Please review and adjust accordingly.'

  reviewFormGroup = this.formBuilder.group({
    title: ['', [Validators.required, Validators.minLength(15), this.noWhitespaceValidator]],
    //content: this.formBuilder.array<Element>([{ children: [{ text: '' }] }], [Validators.required]),
    content: this.formBuilder.array([]),
    replyPost: this.formBuilder.group({
      value: ['', [Validators.required]],
      relationTo: ['', [Validators.required]],
    }),
    surveyMeta: this.formBuilder.group({
      visitMeta: this.formBuilder.group({
        isVirtual: [false, [Validators.required]],
        isNewPatient: [false, [Validators.required]],
        address_id: ['', [Validators.required]],
        insuranceCompanyId: []
      }),
      providerScoreMeta: this.formBuilder.group({
        knowledgeRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
        compassionRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
        listenerRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
        educationRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
      }),
      staffScoreMeta: this.formBuilder.group({
        responsiveRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
        benevolentRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
        timelySchedulingRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
        shortWaitRating: [0, [Validators.required, Validators.min(1), Validators.max(5)]],
      }),
      miscMeta: this.formBuilder.group({
        optBinaryFeedback: this.formBuilder.array([])
      })
    }),
    moderatorMeta: this.formBuilder.group({
      text: this.formBuilder.group({
        'auto-score': [0, [Validators.max(this.autoTxtModeratorThresold)]],
        'auto-result': [{} as IPerspectiveResponse]
      }),
      image: this.formBuilder.group({
        'auto-score': [0, [Validators.max(this.autoImgModeratorThresold)]],
        'auto-result': ['']
      }),
    }),
    gallery: this.formBuilder.group({
      images: this.formBuilder.array([])
    })
  });

  defaultOptionBinaryFeedback = DEFAULT_OPT_BINARY_FEEDBACK;

  locationOptions: {address_id: string, label: string }[] = []

  constructor(config: NgbModalConfig, 
    private modalService: NgbModal, 
    private formBuilder: FormBuilder,
    private moderatorService: ModeratorService,
    private insuranceService: InsuranceService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['target']?.currentValue) {
      // @ts-ignore
      this.isProviderTarget = this.target?.npi;

      // @ts-ignore
      //this.isReviewTarget = this.target instanceof IReview;

      if(this.isProviderTarget) {
        this.targetCollection = 'providers';

        (this.target as IProvider)?.locations?.forEach(x => {
          this.locationOptions.push({address_id: x.address_id, label: `${x.line1}, ${x.city_town}, ${x.state} ${x.zip}`});
        });
        this.locationOptions.push({ address_id: 'OTHER', label: 'OTHER' });

        this.reviewFormGroup.controls.surveyMeta.controls.visitMeta.patchValue({
          isVirtual: false,
          isNewPatient: false,
          address_id: (<IProvider>this.target)?.locations[0]?.address_id || 'OTHER'
        });

        
      }

      //if(this.isReviewTarget) this.targetCollection = 'reviews';

      this.reviewFormGroup.controls.replyPost.patchValue({ 
        value:  this.target.id,
        relationTo: this.targetCollection
      });

    }
  }

  ngOnInit(): void {


    this.getData();

    this.reviewFormGroup.controls.content.valueChanges
      .pipe(debounceTime(2000))
      .subscribe(() => {
        this.moderate()
      });

    this.reviewFormGroup.controls.title.valueChanges
      .pipe(debounceTime(2000))
      .subscribe(() => {
        this.moderate()
      });

    this.reviewFormGroup.statusChanges.subscribe(val => this.formChangeEvent.emit(this.reviewFormGroup));

    this.defaultOptionBinaryFeedback.forEach(item => {
      const convertedItem = Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, [value]])
      );

      this.optBinarFeedbackFormArray().push(this.formBuilder.group(
        convertedItem
      ))
    });

    this.addImage();
    this.addImage();
    this.addImage();
  }

  getData() {
    this.insuranceService.find({ sort: 'group,sortPriority,name', limit: 200 }).subscribe(result => {
      this.insuranceResult = result;
    });
  }

  onOptBinaryFeedbackChange($event: Record<'id'|'value', string>) {
    let dto = { id: $event.id, value: $event.value };

    if(dto.id){
      const controls = this.optBinarFeedbackFormArray()?.controls;
      controls.forEach(formGroup => {
        if(formGroup.value.id === dto.id) {
          dto = {...formGroup.value, ...dto };
          formGroup.patchValue(dto);
        }
      });
    }
  }

  onReviewContextChange($event: Element[]) {
    this.content().clear({emitEvent: false});

    const transformed = $event.map(item => {
      return {
          type: 'paragraph',
          children: [
              //{
                // @ts-ignore
                  ...item.children
              //}
          ]
      };
    });

    transformed.forEach((item: any) => {
      this.content().push(this.formBuilder.group({
        ...item
      }));
    });

    //this.reviewFormGroup.patchValue({ content: $event });
  }

  providerScoreMetaGroup() {
    return this.reviewFormGroup.controls.surveyMeta.controls.providerScoreMeta;
  }

  staffScoreMetaGroup() {
    return this.reviewFormGroup.controls.surveyMeta.controls.staffScoreMeta;
  }

  miscMetaGroup() {
    return this.reviewFormGroup.controls.surveyMeta.controls.miscMeta;
  }

  optBinarFeedbackFormArray() {
    return this.reviewFormGroup.controls.surveyMeta.controls.miscMeta.controls.optBinaryFeedback as FormArray
  }

  getOptBinarFeedbackFormArrayItem(index: number) {
    //console.log(this.optBinarFeedbackGroup().at(index))
    
    return this.optBinarFeedbackFormArray().at(index) as FormGroup;
  }

  content() {
    return this.reviewFormGroup.controls.content as FormArray;
  }

  submitReview() {
    if(this.reviewFormGroup.valid) {
      console.log(this.reviewFormGroup.valid, this.reviewFormGroup.value);
    }
  }

  moderate() {
    this.isModerating = true;
    const content: (string | null | undefined)[] = [];
    content.push(this.reviewFormGroup.controls.title.value);

    this.reviewFormGroup.controls.content.value.forEach(el => {
      // @ts-ignore
      //el.children.forEach(c => content.push(c.text));
      //console.log(el.children);

      // @ts-ignore
      content.push(el.children.text);
      //content.push(el.children.content);
    });
    //console.log(content);
    //console.log(this.reviewFormGroup.controls.content.value);

    this.moderatorService.analyze(content.join(', ')).subscribe(result => {
      //console.log(result);
      this.lastAutoTxtModeratorResult = result;
      this.isModerating = false;
      this.reviewFormGroup.controls.moderatorMeta.controls.text.patchValue({
         "auto-score": result.max,
         "auto-result": result 
      });

      if(result.max < this.autoTxtModeratorThresold)
        this.autoTxtStatus = { msg: this.compliance_positive, isCompliant: true};
      else 
        this.autoTxtStatus = { msg: this.compliance_negative, isCompliant: false};
    });
    
  }

  public noWhitespaceValidator(control) {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }

  get images(): FormArray {
    return this.reviewFormGroup.controls.gallery.controls.images as FormArray;
  }

  addImage(): void {
    const imageFormGroup = this.formBuilder.group({
      file: [null]
    });
    this.images.push(imageFormGroup);
    this.imagePreviewUrls.push({});
  }

  onFileChange(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.images.at(index).get('file')?.setValue(file);

      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreviewUrls[index].dataUrl = reader.result as string;
        
        firstValueFrom(this.moderatorService.analyzeV2([
          { type: 'image_url', image_url: { url: this.imagePreviewUrls[index].dataUrl }}
        ])).then(x => {
          this.imagePreviewUrls[index].moderatorResult = this.listInappropriateReasons(x.results);
          let maxScore = 0;
          let maxCategory = '';

          for (const [category, score] of Object.entries(x.results[0].category_scores)) {
            if (score > maxScore) {
              maxScore = score;
              maxCategory = category;
            }
          }

          this.imagePreviewUrls[index].maxCategoryMapping = { maxCategory, maxScore }
          this.patchModeratorMetaImg();
        });

      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(index: number): void {
    this.images.removeAt(index);
    this.imagePreviewUrls.splice(index, 1);
    this.patchModeratorMetaImg();
  }

  patchModeratorMetaImg() {
    let maxScore = 0;
    let maxCategory = '';

    for (const preview of this.imagePreviewUrls) {
        if (preview.maxCategoryMapping && preview.maxCategoryMapping.maxScore > maxScore) {
            maxScore = preview.maxCategoryMapping.maxScore;
            maxCategory = preview.maxCategoryMapping.maxCategory;
        }
    }

    this.reviewFormGroup.controls.moderatorMeta.controls.image.patchValue({
      "auto-score": maxScore,
      "auto-result": maxCategory 
   })
  }

  listInappropriateReasons(results: IOpenAiModeratorResult[]) {
    const reasons = [];
    const flagged = results[0].flagged;

    if (flagged) {
      for (const [category, score] of Object.entries(results[0].category_scores)) {
          if (score > this.autoImgModeratorThresold) {
              // @ts-ignore
              reasons.push(`${category}`);
          }
      }
    }

    return reasons.length > 0 ? reasons : [];
  }
}

export enum UserRoles {
  admin = 'admin',
  user = 'user',
  provider = 'provider'
}

export const UserRolesLabels = {
  [UserRoles.admin]: 'Administrator',
  [UserRoles.user]: 'User',
  [UserRoles.provider]: 'Provider',
};

import { Injectable } from '@angular/core';
import { ILoginResponse, IResponse, IUser, MeResponse, SuccessMessagePayload } from '@sw-cms/shared-types';
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, of, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { join } from 'lodash';
import { SocialUser, SocialAuthService } from "@abacritt/angularx-social-login";
import * as uuid from 'uuid';
import { LocalStorageService } from './local-storage.service';
import jwt_decode from 'jwt-decode';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private collection: string = 'users';
  private base: string = join([environment.api.url], '/');
  private baseApiUrl: string = join([this.base, 'api', this.collection], '/');

  private _userSubject: BehaviorSubject<IUser | null | undefined> = new BehaviorSubject<IUser | null | undefined>(undefined);
  auth = environment.app.auth;

  public routes = {
    auth: {
      register: () => join([this.base, 'auth/register'], '/'),
      registerProvider: () => join([this.base, 'auth/register/provider'], '/'),
      verify: (token: string) => join([this.base, 'auth/verify', token], '/'),
      login: this.create(this.collection, 'login'),
      social: join([this.base, 'auth/sso/callback'], '/'),
      me: this.create(this.collection, 'me'),
      logout: this.create(this.collection, 'logout'),
      forgotPassword: this.create(this.collection, 'forgot-password'),
      resetPassword: this.create(this.collection, 'reset-password'),
      refresh: this.create(this.collection, 'refresh-token'),
    },
    user: {
      update: (userId: string) => `${this.create(this.collection)}/${userId}`,
    }
  };


  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private featureFlagService: FeatureFlagService
  ) {
    this.fetchUser().subscribe();
  }

  private create(slug: string, path: string = '') {
    return join([this.base, 'api', slug, path], '/');
  }

  get user() {
    return this._userSubject;
  }

  isLoggedIn(): boolean {
    const encodedToken = this.getSession() || "";

    if (!encodedToken)
      return false;

    const decodedToken = jwt_decode(encodedToken);

    if (decodedToken) {
      const expirationDate = decodedToken['exp'] * 1000;

      return Date.now() < expirationDate;
    }

    return false;
  }

  getRoles(): string[] {
    const encodedToken = this.getSession() || "";

    if (!encodedToken)
      return [];

    const decodedToken = jwt_decode(encodedToken);

    return decodedToken?.['roles'] || [];
  }
  /*
  get socialAuthState() {
    return this.socialAuthService.authState;
  }
*/
  me = () => {
    return this.http.get<IResponse<MeResponse>>(
      `${this.routes.auth.me}?depth=3`,
    );
  }

  saveSession = (token: string) => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }

  getSession = () => {
    return localStorage.getItem('token');
  }

  removeSession = () => {
    // if it exists then we set it back after removing the session
    const registeredUserEmail = localStorage.getItem('email');
    localStorage.clear();

    if (registeredUserEmail) localStorage.setItem('email', registeredUserEmail);
    this._userSubject.next(null);
  }

  async verify(email: string, token: string) {
    return firstValueFrom(
      this.http.post<IResponse<ILoginResponse>>(
        this.routes.auth.verify(token),
        {
          email,
        },
      ),
    );
  }

  fetchUser = () => {
    return new Observable<IUser | null>((subscriber) => {
      this.me()
        .pipe(
          catchError((error) => { throw error; }),)
        .subscribe((res: IResponse<MeResponse>) => {
          if (!res) {
            subscriber.next(null);
            return;
          }
          if (res.success && res.payload.user) {
            if (res.payload.token !== this.getSession()) {
              this.saveSession(res.payload.token);
            }

            // @ts-ignore
            if(res.payload.user.careTeam){
              // @ts-ignore
              res.payload.user.careTeam.forEach(x => this.localStorageService.saveData(`favorite_provider_${x.providerId}`, JSON.stringify(true)));
            }

            this._userSubject.next(res.payload.user);
            subscriber.next(res.payload.user);
          } else {
            this._userSubject.next(null);
            subscriber.next(null);
          }

          //@ts-ignore
          this.featureFlagService.updateContext({userId: res?.payload?.user?.username})

          subscriber.complete();
        });
    });
  }

  login = async (username: string, password: string) => {
    switch (this.auth) {
      case 'discourse':
        return null;
        break;
      default: {
        const res = await firstValueFrom(
          this.http.post<IResponse<ILoginResponse>>(
            this.routes.auth.login,
            {
              email: username,
              password: password,
            },
            { withCredentials: true },
          )
        );

        if (res.success) {
          this.saveSession(res.payload.token);
          await firstValueFrom(this.fetchUser());
        }

        return res;
      }
    }
  }

  logout() {
    this.removeSession();

    return this.http.post<IResponse<Record<string, any>>>(
      this.routes.auth.logout,
      {},
      { withCredentials: true },
    );
  }

  socialLoginProcessing = async (socialUser: SocialUser) => {
    const res = await firstValueFrom(
      this.http.post<IResponse<ILoginResponse>>(
        this.routes.auth.social,
        socialUser,
        { withCredentials: true },
      )
    );

    if (res.success) {
      this.saveSession(res.payload.token);
      await firstValueFrom(this.fetchUser());
    }

    return res;
  }

  register<T>(data: T) {
    return firstValueFrom(
      this.http.post<IResponse<SuccessMessagePayload>>(
        this.routes.auth.register(),
        data,
      ),
    );
  }

  registerProvider<T>(data: T) {
    return firstValueFrom(
      this.http.post<IResponse<SuccessMessagePayload>>(
        this.routes.auth.registerProvider(),
        data,
      ),
    );
  }

  forgotPassword(email: string) {
    return firstValueFrom(
      this.http.post<IResponse<{ message: string }>>(
        this.routes.auth.forgotPassword,
        {
          email,
        },
      ),
    );
  }

  resetPassword(token: string, newPassword: string) {
    return firstValueFrom(
      this.http.post<IResponse<{ message: string }>>(
        this.routes.auth.resetPassword,
        {
          token,
          password: newPassword,
        },
      ),
    );
  }

  discourseSsoLogin() {
    const url = window.location.href;

    if(!url.includes('/auth'))
      this.localStorageService.saveData(this.localStorageService.LASTURL, url);

    const previousUrl = this.localStorageService.getData(this.localStorageService.LASTURL) ?? window.location.href;
    const nonce = uuid.v4();
    const return_sso_url = encodeURIComponent(`${AuthUIPaths.discourse.sso.loginResponseUrl}?&return_sso_url=${previousUrl}&provider=${environment.app.auth}`);
    let customParams = '';

    const vsId = this.localStorageService.getData(this.localStorageService.CLAIM_VSID);
    const provider = this.localStorageService.getData(this.localStorageService.CLAIM_PROV);
    const email = this.localStorageService.getData(this.localStorageService.CLAIM_EMAIL);

    if (vsId) {
      customParams += `vsId=${vsId}&`;
    }

    if (provider) {
      customParams += `providerId=${JSON.parse(provider)._id}&`;
    }

    if (email) {
      customParams += `email=${email}&`;
    }

    window.location.href = `${AuthUIPaths.discourse.sso.loginRequestUrl}?nonce=${nonce}&return_sso_url=${return_sso_url}&${customParams}`;
  }

  discourseSsoLogout() {
    const previousUrl = environment.web.url;
    const nonce = uuid.v4();
    const return_sso_url = encodeURIComponent(`${AuthUIPaths.discourse.sso.logOutResponseUrl}?&return_sso_url=${previousUrl}&provider=${environment.app.auth}`);
    window.location.href = `${AuthUIPaths.discourse.sso.logOutRequestUrl}?nonce=${nonce}&return_sso_url=${return_sso_url}`;
  }
}

export const AuthUIPaths = {
  discourse: {
    sso: {
      loginRequestUrl: `${environment.api.url}/${environment.api.endpoints.sso.login}`,
      loginResponseUrl: `${environment.web.url}/${environment.web.endpoints.sso.login}`,
      logOutRequestUrl: `${environment.api.url}/${environment.api.endpoints.sso.logout}`,
      logOutResponseUrl: `${environment.web.url}/${environment.web.endpoints.sso.logout}`,

    }
  },
  login: `auth/login`,
  register: `auth/register`,
  forgotPassword: `auth/forgot-password`,
  verify: `auth/verify`,
  logout: `auth/logout`,
};
import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {
  defaultMetaKeyWords: string[] = [
    'health',
  ];

  constructor(private titleService: Title, private metaService: Meta) {}

  setMetaTag(
    title: string,
    description: string = '',
    keywords: string[] = [],
    otherTags: MetaDefinition[] = []
  ): Observable<boolean> {
    try {
      if (description) {
        description = description.replace(/(<([^>]+)>)/gi, '');
      }

      const metaKeyWords = this.defaultMetaKeyWords.concat(keywords);
      const metaTitle = `${this.getCompanyName()} | ${title}`;
      const metaTags: MetaDefinition[] = [
        { name: 'keywords', content: metaKeyWords.join(', ') },
        { name: 'description', content: description || '' },
      ];

      this.titleService.setTitle(metaTitle);

      metaTags.concat(otherTags);
      metaTags.forEach((tag) => {
        this.metaService.updateTag(tag);
      });

      return of(true);
    } catch {
      return throwError('Failed to set meta tag');
    }
  }

  getCompanyName() {
    return environment.company.name.replace(', LLC','');
  }
}


import { IBaseSearchCriteria } from "./base-search-criteria.model"

export interface IReviewSearchCriteria extends IBaseSearchCriteria {

}

export interface IReviewSearchResult {
    docs: IReview[]
    totalDocs: number
    limit: number
    totalPages: number
    page: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prevPage: number | null
    nextPage: number
  }
  
  export interface IReview {
    id: string
    createdAt: string
    updatedAt: string
    title: string
    content: any
    surveyMeta: ISurveyMeta
    replyPost: IReplyPost
    isApproved: boolean
    aggMeta: IAggMeta | null
    author: string
    createdBy: CreatedBy | string | null
    reactionMeta: IReactionMeta[]
    moderatorMeta: any
    gallery?: any[]
  }
  
  export interface IVisitMeta {
    isNewPatient: boolean | null
    isVirtual: boolean | null
    address_id: string | null
    waitTimeMinutes: number | null
  }
  
  export interface IScoreMeta {}
  
  export interface ICompetenciesMeta {
    LGBTQ: boolean | null
  }
  
  export interface IReplyPost {
    value: IValue | string
    relationTo: string
  }
  
  interface IValue {
    id: string
    title: string
    content: any
    surveyMeta: ISurveyMeta
    replyPost: IReplyPost
    isApproved: boolean
    author: string
    createdBy: string
    createdAt: string
    updatedAt: string
    reactionMeta: IReactionMeta[]
    aggMeta: IAggMeta
  }
  
  export interface ISurveyMeta {
    visitMeta: IVisitMeta
    compositeRating: number | null
    providerCompositeRating: number | null
    staffCompositeRating: number | null
    providerScoreMeta: IProviderScoreMeta
    staffScoreMeta: IStaffScoreMeta
    miscMeta: IMiscMeta
    competenciesMeta: ICompetenciesMeta
  }

  export interface IProviderScoreMeta {
    knowledgeRating: number | null
    compassionRating: number | null
    listenerRating: number | null
    educationRating: number | null
  }
  
  export interface IStaffScoreMeta {
    responsiveRating: number | null
    benevolentRating: number | null
    timelySchedulingRating: number | null
    shortWaitRating: number | null
  }

  export interface IMiscMeta {
    optBinaryFeedback: IOptBinaryFeedback[] | null,
    timelyAppt: string | null
    patientPrograms: string | null
    sameDayVisit: string | null
    cleanOffice: string | null
    LGBTQ: string | null
    easyParking: string | null
    weekendAvailability: string | null
    virtualCare: string | null
    urgentCare: string | null
    onsiteResources: string | null
  }
  
  export interface IOptBinaryFeedback {
    id: string
    label: string
    value: string
    displayOrder: number
    isActive: boolean
  }
  export interface IReactionMeta {
    reaction: string
    count: number
    id: string
  }
  
  export interface IAggMeta {
    totalReplies: number
    totalReactions: number
  }
  
  
  interface CreatedBy {
    id: string
    firstName: string
    username: string
    roles: string[]
    createdAt: string
    updatedAt: string
  }


  export interface IReaction {
    id: string
    reaction: string
  }
  


  export const DEFAULT_OPT_BINARY_FEEDBACK = [
    { id: 'timelyAppt', label: 'Timely Appointments', value: 'NA', displayOrder: 1, isActive: true },
    { id: 'patientPrograms', label: 'Provides Patient Programs', value: 'NA', displayOrder: 2, isActive: true  },
    { id: 'sameDayVisit', label: 'Same Day Sick Visit', value: 'NA', displayOrder: 3, isActive: true  },
    { id: 'cleanOffice', label: 'Clean Environment', value: 'NA', displayOrder: 4, isActive: true  },
    { id: 'inclusive', label: 'Inclusive Atmosphere', value: 'NA', displayOrder: 5, isActive: true  },
    { id: 'easyParking', label: 'Easy Parking', value: 'NA', displayOrder: 6, isActive: true  },
    { id: 'weekendAvailability', label: 'Weekend Availability', value: 'NA', displayOrder: 7, isActive: true  },
    { id: 'noRush', label: 'Did Not Rush Visit', value: 'NA', displayOrder: 8, isActive: true  },
    { id: 'urgentCare', label: 'Urgent Care Offered', value: 'NA', displayOrder: 9, isActive: true  },
    { id: 'onsiteResources', label: 'On-site Resources', value: 'NA', displayOrder: 10, isActive: true  }
  ];
export interface IDiscourseSignedPayload {
    admin?: boolean;
    email?: string;
    external_id?: string;
    groups?: string;
    moderator?: boolean;
    nonce: string;
    return_sso_url?: string;
    username: string;
    name?: string;
    avatar_url?: string;
  }
  
export interface IDiscourseWebhookEventPayload {
  event: any
  headers: any[]
}
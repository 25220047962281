export interface IUmamiSendPayload {
    payload: EventPayload;
    type: string;
}

interface EventPayload {
    hostname?: string;
    language?: string;
    referrer?: string;
    screen?: string;
    title?: string;
    url?: string;
    website?: string;
    name?: string;
    data?: any;
}

import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'web-raffle-ad',
  templateUrl: './raffle-ad.component.html',
  styleUrls: ['./raffle-ad.component.scss'],
})
export class RaffleAdComponent {
  @Input() promptFormat: string = 'lg';

  @ViewChild('eligRuleModal') private eligRuleModal;
  eligRuleModalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  openEligRules() {
    this.eligRuleModalRef = this.modalService.open(this.eligRuleModal, { fullscreen: 'md', size: 'xl', scrollable: true });
  }
}

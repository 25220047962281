import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'web-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.scss'],
})
export class BaseComponentComponent implements OnDestroy, OnInit {
  destroy$ = new Subject<void>();

  ngOnInit(): void {
    const currentUrl = window.location.href;

    if(!currentUrl.includes('/auth'))
      localStorage.setItem('lurl', currentUrl);
  }

  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

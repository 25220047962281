<div class="list">
    <web-review *ngFor="let doc of reviewsSearchResult?.docs" [review]="doc" [mode]="mode" (openJournal)="viewJournal($event)"></web-review>
</div>

<ng-template #journalModal let-modal>
    <div class="modal-header">
        <h3><span style="font-size: 0.5em;">{{reviewJournal?.key?.createdBy?.username}} Journal</span><br/>
            RE: {{reviewJournal?.journal[0].id.replyPost.value.title}}
        </h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <table class="rating">
            <tr>
                <td>Overall:</td>
                <td rowspan="3">&nbsp;</td>
                <td><web-star-rating [rating]="reviewJournal?.avgCompositeRating" [max]="5" size="sm" [readonly]="true" [showLabel]="true"></web-star-rating></td>
            </tr>
            <tr>
                <td>Provider Rating:</td>
                <td><web-star-rating [rating]="reviewJournal?.avgProviderCompositeRating" [max]="5" size="sm" [readonly]="true" [showLabel]="true"></web-star-rating></td>
            </tr>
            <tr>
                <td>Staff Rating:</td>
                <td><web-star-rating [rating]="reviewJournal?.avgStaffCompositeRating" [max]="5" size="sm" [readonly]="true" [showLabel]="true"></web-star-rating></td>
            </tr>
        </table>

        <div class="vtimeline">
            <web-review *ngFor="let doc of reviewJournal?.journal; let i = index" [journalIndex]="i" [review]="doc.id" [mode]="'public-journal'"></web-review>
          </div>
    </div>
</ng-template>
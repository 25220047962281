import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-pwa-install',
  templateUrl: './pwa-install.component.html',
  styleUrls: ['./pwa-install.component.scss'],
})
export class PwaInstallComponent implements OnInit {
  deferredPrompt: any;
  isInstallPromptVisible = true;

  constructor() { }

  ngOnInit(): void {
    console.log('Check if the cookie is set to avoid showing the prompt again');
    if (this.getCookie('pwa-dismissed') !== 'true') {
      // Listen for the beforeinstallprompt event
      window.addEventListener('beforeinstallprompt', (event) => {
        console.log('Prevent the default install prompt');
        event.preventDefault();
        this.deferredPrompt = event;
        this.isInstallPromptVisible = true;
      });
    }
  }

  // Show the install prompt
  promptInstall(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
    }
  }

  // Dismiss the install prompt and set a cookie
  dismissPrompt(): void {
    this.isInstallPromptVisible = false;
    this.setCookie('pwa-dismissed', 'true', 30); // Set cookie for 30 days
  }

  // Helper function to set a cookie
  setCookie(name: string, value: string, days: number): void {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  // Helper function to get a cookie
  getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}

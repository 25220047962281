import { IBaseSearchCriteria } from "./base-search-criteria.model";


export interface IProviderSearchCriteria extends IBaseSearchCriteria, ICriteria {

}

export interface IProviderSearchNpiUnclaimedCriteria {
  npi: string,
  licenseNumber?: string,
  provider?: IProvider
}
  
export interface IProviderSearchResult {
  totalDocs: number
  totalPages: number
  docs: IProvider[]
  facets: IFacets
  limit: number
  page: number
  hasPrevPage: boolean
  hasNextPage: boolean
  prevPage: number | null;
  nextPage: number | null
  criteria: ICriteria
  }

  export interface ILanguage {
    language_id: string;
    name: string;
    fluent: boolean;
  }

  export interface ILicense {
    code: string;
    taxonomy_group: string;
    desc: string;
    state: string;
    license: string;
    primary: boolean;
  }

  export interface IMedicalSchool {
    name: string;
    grad_yr: number;
  }
  
  export interface IProvider {
    id: string;
    npi: string;
    title: string;
    first_name: string;
    gender: string;
    import_date: Date;
    is_visible: boolean;
    languages: ILanguage[];
    last_name: string;
    biography: string;
    licenses: ILicense[];
    locations: ILocation[];
    medical_credential: string;
    medical_school: IMedicalSchool;
    middle_initial: string;
    name_prefix: string | null;
    name_suffix: string | null;
    sole_proprietor: boolean;
    specialty: string;
    review_config: {
      enableReviews: boolean | null;
      rate_limit: any;
    };
    status: string;
    sub_specialties: any[];
    updatedAt: string;
    location_meta: ILocationMeta[];
    nearestLocation: INearestLocation;
    surveyMeta: ISurveyMeta;
    exp_yrs?: number;
    website?: string;
    otherRatings?: any;
    prov_plan?: string;
  }

  export interface ICmsMeta {
    first_name: string
    last_name: string
    middle_name: string
    gender: string
    credentials: string
    medical_school: string
    grad_yr: number
    ind_pac_id: string
    ind_enroll_id: string
    specialties: ISpecialty[]
    locations: ILocation[]
  }

  export interface ISpecialty {
    name: string
    isPrimary: boolean
  }

  export interface ILocation {
    address_id: string
    line1: string
    line2?: string
    city_town: string
    state: string
    zip: string
    facility_name: string
    incomplete_indicator: boolean
    telephone_number: string
  }

  export interface IGeoPoint {
    type: string
    coordinates: number[]
  }

  export interface ILocationMeta {
    address_id: string
    source: string
    geoPoint: IGeoPoint | number[]
    distance: number
    distance_unit: string
  }

  export interface INearestLocation extends ILocation {
    address_id: string
    source: string
    geoPoint: IGeoPoint
    distance: number
    distance_unit: string
  }

  export interface IFacets {
    by_gender: IFacet[]
    by_specialty: IFacet[]
  }
  
  export interface IFacet {
    _id: string
    count: number
  }
  
  export interface ICriteria {
    providerToken?: string
    locationToken?: string
    page?: number
    pageSize?: number
    radius?: number
    centerPoint?: number[]
    sort?: any
  }



export interface IProviderTokenSuggestion {
  id: string | null,
  group: string
  label: string
  score: number
  subGroup?: string
}
  

export type IProviderTokenSuggestionResult = IProviderTokenSuggestion[]


 interface ISurveyMeta {
  compositeRating: number
  providerCompositeRating: number
  staffCompositeRating: number
  providerScoreMeta: IProviderScoreMeta
  staffScoreMeta: IStaffScoreMeta
}

 interface IProviderScoreMeta {
  categories: IProviderRatingCategories
  tallies: ITally[]
}

interface IProviderRatingCategories {
  knowledgeRating: number
  compassionRating: number
  listenerRating: number
  educationRating: number
}

interface IStaffScoreMeta {
  categories: IStaffRatingCategories
  tallies: ITally[]
}

interface IStaffRatingCategories {
  responsiveRating: number
  benevolentRating: number
  timelySchedulingRating: number
  shortWaitRating: number
}

interface ITally {
  rating: number
  count: number
}

export interface IPatientFeedbackAgg {
  values: IPatientFeedbackAggValue[]
  total: number
  totalCompleted: number
  id: string
  label: string
  percentage: IPatientFeedbackAggPercentage[],
  percentageCompleted: IPatientFeedbackAggPercentage[]
}

interface IPatientFeedbackAggValue {
  value: string
  count: number
}

interface IPatientFeedbackAggPercentage {
  value: string
  count: number
  percentage: number
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { join } from 'lodash';
import { environment } from '../../../environments/environment';
import { IReaction, IReview, IReviewSearchCriteria, IReviewSearchResult } from '../models/review.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import * as qs from 'qs';
import { IInsuranceSearchCriteria, IInsuranceSearchResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  private collection: string = 'insurance-companies';
  private baseUrl: string = join([environment.api.url, 'api', this.collection], '/');

  constructor(private http: HttpClient) { }

  find = (criteria: IInsuranceSearchCriteria): Observable<IInsuranceSearchResult> => {
    let url = `${this.baseUrl}?`;

    if (criteria.draft) {
      url += `draft=${criteria.draft}&`;
    }

    if (criteria.depth) {
      url += `depth=${criteria.depth}&`;
    }

    if (criteria.sort) {
      url += `sortBy=${criteria.sort}&`;
    }

    if (criteria.page) {
      url += `page=${criteria.page}&`;
    }

    if (criteria.limit) {
      url += `limit=${criteria.limit}&`;
    }

    if (criteria.query) {
      const stringifiedQuery = qs.stringify(
        {
          where: criteria.query, // ensure that `qs` adds the `where` property, too!
        },
        { addQueryPrefix: false },
      )

      url += `${stringifiedQuery}&`;
    }

    return this.http
      .get<any>(url, {})
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  };
}

<div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
    <ul class="navigation clearfix">
        <li *ngIf="isLoggedIn" class="dropdown mobile-only"><a>My Account</a>
            <ul [id]="'menuItemMyAccountList' + rnd">
                <li class="nav-item"><a [routerLink]="['/my/review-journal']" class="nav-link"><i class='bx bx-list-ul'></i><span>&nbsp;My Review Journal</span></a></li>
                <li class="nav-item"><a [routerLink]="['/my/care-team']" class="nav-link"><i class='bx bx-plus-medical'></i><span>&nbsp;My Care Team</span></a></li>
                <li class="nav-item"><a [routerLink]="['/auth/logout']" class="nav-link"><i class='bx bx-log-out'></i><span>&nbsp;Logout</span></a></li>
            </ul>
            <div class="dropdown-btn" (click)="toggleMobileMemuItem($event, 'menuItemMyAccountList' + rnd)"><span class="fas fa-angle-down"></span></div>
        </li>
        <li>
            <a [href]="isLoggedIn ? '/care-community' : '/#care-community'" class="nav-link">Care Community</a>
        </li>
        <li>
            <a routerLink="/" class="nav-link">Find A Doctor</a>
        </li>
        <li>
            <a routerLink="/about-us">About us</a>
        </li>
        <li class="dropdown">
            <a href="index.html">Resources</a>
            <ul [id]="'menuItemResourceList' + rnd">
                <li><a routerLink="." fragment="about-us" class="nav-link">Social Healthcare Platform</a></li>
                <li><a routerLink="/about-us">Company Overview</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
                <li class="nav-item"><a routerLink="/" fragment="faq"  class="nav-link" routerLinkActive="active">Frequently Asked Questions</a></li>
            </ul>
            <div class="dropdown-btn" (click)="toggleMobileMemuItem($event, 'menuItemResourceList' + rnd)"><span class="fas fa-angle-down"></span></div>
        </li> 
        <li><a routerLink="/provider-service">Providers</a></li>
    </ul>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMask',
})
export class EmailMaskPipe implements PipeTransform {

  transform(email: string): string {
    if (!email || !email.includes('@')) {
      return email; // Return as is if not a valid email
    }

    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.length > 3 
      ? localPart.slice(0, 2) + '*'.repeat(localPart.length - 3) + localPart.slice(-1)
      : localPart[0] + '*'.repeat(localPart.length - 1);

    const maskedDomainPart = localPart.length > 3 
      ? domain.slice(0, 2) + '*'.repeat(domain.length - 3) + domain.slice(-1)
      : domain[0] + '*'.repeat(domain.length - 1);

    return `${maskedLocalPart}@${maskedDomainPart}`;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FEATURE_FLAGS, IUser } from '@sw-cms/shared-types';
import { FeatureFlagService } from '../../services';

@Component({
  selector: 'web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() user: IUser | null | undefined = null;
  @Input() isLoggedIn: boolean = false;
  
  company: any = environment.company;
  currentYear: number = (new Date()).getFullYear();
  social: any = environment.social;
  providerSignUpEnabled = environment.toggle.providerSignUpEnabled;
  providerSignUpLaunchDesc = environment.toggle.providerSignUpLaunchDesc;

  constructor(private featureFlagService: FeatureFlagService) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {

  }

}

<div *ngIf="mode === 'public'" class="single-review-box">
    <web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5" size="sm" [readonly]="true"
        [showLabel]="true"></web-star-rating>
    <div *ngIf="isProviderReview" class="col-md-12 col-12 col-sm-12 p-0">
        <h4>{{review.title}}</h4>
    </div>
    <web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor>
    <h6>
        <figure class="image-box"><img src="{{ review?.createdBy?.avatar_url ?? review?.createdBy?.avatar_default_url}}"
                alt=""></figure>{{review?.createdBy?.username}} <span>- {{review.createdAt | date: 'longDate'}}</span>
    </h6>

    <ngx-gallery *ngIf="galleryImages?.length" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
</div>

<div *ngIf="mode === 'owner'" class="single-review-box owner">
    <h6 class="mb-2"><span>{{review.createdAt | date: 'medium'}}</span></h6>
    <div *ngIf="isProviderReview" class="col-md-12 col-12 col-sm-12 p-0 mb-3">
        <h5 class="provider-title">{{review.replyPost.value.title}}<br /><span class="provider-label">Provider</span>
        </h5>
    </div>

    <div class="row">
        <div class="col-md-6">
            <h6 class="review-title">{{review.title}}</h6>

            <table class="rating">
                <tr>
                    <td>Overall:</td>
                    <td rowspan="3">&nbsp;</td>
                    <td><web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5" size="sm"
                            [readonly]="true" [showLabel]="true"></web-star-rating></td>
                </tr>
                <tr>
                    <td>Provider Rating:</td>
                    <td><web-star-rating [rating]="review?.surveyMeta.providerCompositeRating" [max]="5" size="sm"
                            [readonly]="true" [showLabel]="true"></web-star-rating></td>
                </tr>
                <tr>
                    <td>Staff Rating:</td>
                    <td><web-star-rating [rating]="review?.surveyMeta.staffCompositeRating" [max]="5" size="sm"
                            [readonly]="true" [showLabel]="true"></web-star-rating></td>
                </tr>
            </table>

            <web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor>
            <ngx-gallery *ngIf="galleryImages?.length" [options]="galleryOptions"
                [images]="galleryImages"></ngx-gallery>
        </div>
        <div class="col-md-6">
            <!--<h6 *ngIf="optBinaryFeedback.length > 0">Additional Feedback</h6>-->
            <div *ngFor="let feedback of optBinaryFeedback">
                <i class='bx' [ngClass]="{ 'bxs-check-square': feedback.value === 'Y', 'bxs-x-square':  feedback.value === 'N' }"></i> {{feedback.label}}
            </div>
        </div>
    </div>

</div>
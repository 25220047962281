import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as qs from 'qs';
import { COMMENT_EMOJI_LIST, IOptBinaryFeedback, IReaction, IReview, IReviewSearchCriteria, IReviewSearchResult, REVIEW_EMOJI_LIST } from '../../models';
import { ReviewService } from '../../services';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-15';

@Component({
  selector: 'web-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, OnChanges {
  @Input() review: IReview;
  @Input() level: number = 0;
  @Input() mode: 'public' | 'owner' | 'provider' = 'public';

  isProviderReview: boolean = false;
  showReplies: boolean = false;
  replyLevel: number = 0;
  replies: IReviewSearchResult;
  emojiOptions: any = [];
  commentExpanded: boolean = false;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  optBinaryFeedback: IOptBinaryFeedback[];
  
  constructor(
    private reviewService: ReviewService) { }
  
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void  {
    if(changes['review']?.currentValue) {
      this.isProviderReview = this.review.replyPost.relationTo === 'providers';
      this.emojiOptions = this.isProviderReview ? REVIEW_EMOJI_LIST : COMMENT_EMOJI_LIST;
      this.optBinaryFeedback = this.review.surveyMeta?.miscMeta?.optBinaryFeedback?.filter(x => x.value !== "NA").sort((a, b) => b.value.localeCompare(a.value)) ?? [];

      if(this.review.gallery) {
        this.galleryOptions = [
          { image: false, height: "75px", previewCloseOnClick: true, previewCloseOnEsc: true },
          { breakpoint: 400, width: "100%", height: "75px" }
        ];

        this.galleryImages = this.review.gallery.map(x => ({
              small: x.file.sizes.thumbnail.url,
              medium: x.file.sizes.card.url,
              big: x.file.sizes.tablet.url
        })); 
      }
    }
  }

  loadReplies(page: number = 1, limit: number = 3) {

    if(!this.showReplies){
      const criteria = {
        page: page,
        limit: limit,
        query: {
          'replyPost.value': {
            equals: this.review.id,
          }
        }
      } as IReviewSearchCriteria;

      this.reviewService.find(criteria).subscribe(result => {
        this.replies = result;
        this.showReplies = true;
        this.replyLevel = this.level + 1;
        this.commentExpanded = true;
      });
    }
    else {
      this.showReplies = false;
    }
  }

  logReaction(symbol: string) {
    const reaction = {
      id: this.review.id,
      reaction: symbol
    } as IReaction;

    this.reviewService.logReaction(reaction).subscribe(x => {
      const existingReaction = this.review.reactionMeta.find(reaction => reaction.reaction === this.reviewService.convertToCodePoint(symbol))
    
      if(existingReaction) existingReaction.count++;
      else this.review.reactionMeta.push({reaction: this.reviewService.convertToCodePoint(symbol), count: 1, id: 'temp'});
    });
  }

  
}

export interface ITimeSeriesEvent {
    metadata: Metadata
    timestamp: Date
    value: number,
    subType: string,
    createdBy: string
  }
  
  export interface Metadata {
    key: string
    type: string
  }


export type reactionMeta = { reaction?: string, count?: number };